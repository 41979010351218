interface Props {
  onClick: () => void;
  isSelected: boolean;
  name: string;
  imgSrc?: string;
}

const SidebarMenuItem = ({ onClick, isSelected, name, imgSrc }: Props) => {
  return (
    <div className="flex flex-row items-center" onClick={onClick}>
      {imgSrc && <img src={imgSrc} alt="menu_item" />}
      <button
        className={`ml-2 ${
          isSelected ? 'text-mainWhite' : 'text-dividerGray'
        } font-inter ${
          isSelected ? 'font-semibold' : 'font-normal'
        } text-base leading-5`}
      >
        {name}
      </button>
    </div>
  );
};

export default SidebarMenuItem;

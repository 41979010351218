import { useTranslation } from 'react-i18next';
import { COMMON_TRANSLATE_KEYS } from '../../constants/translate-keys';
import MyDrawer from '../Drawer';
import PlayerImage from '../PlayerImage';
import { getPlayerRole } from '../../utils/players';
import { Team } from '../../types/team';
import { Player } from '../../types/players';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  team: Team | undefined;
  onPlayerClick: (player: Player) => void;
};

const ReservationPlayersModal = ({
  isOpen,
  onClose,
  team,
  onPlayerClick,
}: Props) => {
  const { t } = useTranslation();

  if (!team) {
    return <></>;
  }

  return (
    <MyDrawer isOpen={isOpen} onClose={onClose}>
      <div className="h-full overflow-y-auto">
        <div className="flex justify-end">
          <button onClick={onClose} className="text-white">
            X
          </button>
        </div>
        <div className="bg-additionalBlack rounded-lg">
          <div className="mt-4 mb-8">
            <div className="flex flex-row justify-between">
              <p className="text-lg font-semibold">
                {t(COMMON_TRANSLATE_KEYS.PLAYERS, { count: 2 })}
              </p>
            </div>
            <div className="grid grid-cols-4 gap-4 mt-4">
              {team.players.map((player, index) => (
                <PlayerImage
                  key={index}
                  name={player.name}
                  role={getPlayerRole(player, team)}
                  imageUrl={player.imageUrl}
                  onClick={() => onPlayerClick(player)}
                  hidden={false}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </MyDrawer>
  );
};

export default ReservationPlayersModal;

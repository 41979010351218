import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { useParams, Link, useSearchParams } from 'react-router-dom';
import Button from '../components/buttons/Button';
import FieldCard from '../components/cards/FieldCard';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../utils/token';
import { COMMON_TRANSLATE_KEYS } from '../constants/translate-keys';
import { useTranslation } from 'react-i18next';
import { Field } from '../types';
import Breadcrumb from '../components/Breadcrumb';
import { useComplexes } from '../hooks/useComplexes';

const initialPageLimit = 8;

const ArenaFields = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const [fields, setFields] = useState<Field[]>([]);
  const [pageLimit, setPageLimit] = useState(initialPageLimit);
  const [hasMoreFields, setHasMoreFields] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const name = searchParams.get('name');

  const { complexes } = useComplexes();

  const complex = useMemo(() => {
    return complexes.find((c) => c.id === Number(id)) || null;
  }, [complexes, id]);

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_ENDPOINT
          }/owner/complexes/${id}/fields?page=${1}&limit=${pageLimit}`,
          {
            headers: {
              Authorization: `Bearer ${getToken()}`,
            },
            params: {
              excludeExpired: true,
            },
          }
        );
        setFields(response.data.fields);
        setHasMoreFields(response.data.hasNext);
        console.log('Fields are fetched:', response.data.fields);
        console.log('Complex ID:', id);
      } catch (error) {
        console.error('Error fetching fields:', error);
      }
    };

    fetchFields();
  }, [id, pageLimit]);

  const loadMore = () => {
    if (hasMoreFields) {
      setPageLimit(pageLimit + initialPageLimit);
    }
  };

  return (
    <div className="mx-[30px] mb-10">
      <Breadcrumb
        className="sm:mb-5 mb-10"
        links={[
          {
            to: '/my-fields',
            name: t(COMMON_TRANSLATE_KEYS.COMPLEXES),
          },
        ]}
      >
        {name} ({t(COMMON_TRANSLATE_KEYS.FIELDS)})
      </Breadcrumb>
      <div className="flex flex-wrap gap-4">
        {fields.map((field) => (
          <FieldCard
            key={field.id}
            title={field.name}
            location={`${field.city}, ${field.country}`}
            field={field}
            complex={complex!}
            width="lg"
          >
            <Button
              backgroundColor="bg-secondaryBlack"
              textColor="text-mainBlue"
              border="1"
              borderColor="border-mainBlue"
              className="max-w-[35rem]"
              onClick={() =>
                navigate(`/arena/${id}/field-reservation/${field.id}`)
              }
            >
              {t(COMMON_TRANSLATE_KEYS.RESERVATIONS)}
            </Button>
          </FieldCard>
        ))}
        {fields.length === 0 && (
          <h1 className="text-lg">
            <span className="text-textGray">{t(COMMON_TRANSLATE_KEYS.NO_FIELDS)}</span>
            {' '}
            <Link className="text-mainBlue hover:underline" to={`/arena/add-field/${id}`}>{t(COMMON_TRANSLATE_KEYS.HERE)}</Link>
          </h1>
        )}
      </div>
      {fields.length > 0 && hasMoreFields && (
        <Button onClick={loadMore}>{t(COMMON_TRANSLATE_KEYS.LOAD_MORE)}</Button>
      )}
    </div>
  );
};

export default ArenaFields;

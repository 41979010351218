import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { Dialog } from "@headlessui/react";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "../buttons/Button";
import Divider from "../Divider";
import { Complex } from "../../types";
import { ROUTE_EDIT_ARENA_INFO } from "../../router/constants";
import { COMMON_TRANSLATE_KEYS } from "../../constants/translate-keys";

interface Props {
  complex: Complex;
  handleDeleteComplex: (id: number) => Promise<void>;
}

const ComplexSettingsCard = ({ complex, handleDeleteComplex }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleConfirmDelete = useCallback(
    async (complexId: number) => {
      await handleDeleteComplex(complexId);
      closeModal();
    },
    [handleDeleteComplex]
  );

  return (
    <div className="bg-[#222223] w-full max-w-[27rem] p-6 rounded-md">
      <div className="mb-6">
        <div className="flex flex-col justify-between">
          <span className="w-full h-32">
            <p className="text-lg text-mainWhite font-bold">{complex.name}</p>
            <div className="text-[#ACACAC] break-words">{complex.address}</div>
          </span>
          <div className="bg-[#5BFDA6] w-fit py-2 px-6 rounded-3xl inline-flex gap-2">
            <CheckCircleIcon width={25} />
            <p className="text-black">{t(COMMON_TRANSLATE_KEYS.FEATURED)}</p>
          </div>
        </div>
      </div>

      <Divider mb={4} mt={4} color="bg-[#4A4A4A]" px={4} />

      <div className="flex flex-col gap-4">
        <Button
          borderColor="border-mainBlue"
          border="border"
          textColor="text-mainBlue"
          onClick={() =>
            navigate(`/arena/${complex.id}/fields?name=${complex.name}`)
          }
        >
          {t(COMMON_TRANSLATE_KEYS.VIEW_FIELDS)}
        </Button>

        <Button
          backgroundColor="bg-functionalGreen"
          textColor="text-gray-800"
          onClick={() => navigate(`/arena/add-field/${complex.id}`)}
        >
          {t(COMMON_TRANSLATE_KEYS.ADD_FIELD)}
        </Button>
      </div>

      <Divider mb={4} mt={4} color="bg-[#4A4A4A]" px={4} />

      <div className="flex flex-col gap-4">
        <Button
          textColor="text-mainBlue"
          borderColor="border-mainBlue"
          border="border"
          onClick={() => navigate(`${ROUTE_EDIT_ARENA_INFO}/${complex.id}`)}
        >
          {t(COMMON_TRANSLATE_KEYS.EDIT_COMPLEX)}
        </Button>

        <Button
          textColor="text-cancel"
          borderColor="border-cancel"
          border="border"
          onClick={() => openModal()}
        >
          {t(COMMON_TRANSLATE_KEYS.DELETE_COMPLEX)}
        </Button>
      </div>

      <Dialog
        open={isOpen}
        onClose={closeModal}
        className="fixed inset-0 flex items-center justify-center z-50"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black  opacity-50" />
        <div className="bg-gray-900 w-[344px] p-6 rounded-md absolute top-212 left-468">
          <Dialog.Title>
            <div className="flex flex-row justify-around text-white font-bold mb-6">
              <span>{`${t(
                COMMON_TRANSLATE_KEYS.DELETE_COMPLEX_CONFIRMATION_TEXT,
                { complex: complex.name }
              )} ?`}</span>
              <button onClick={closeModal}>X</button>
            </div>
          </Dialog.Title>

          <div className="flex flex-row gap-8">
            <Button
              onClick={async () => await handleConfirmDelete(complex.id)}
              type="button"
              backgroundColor="bg-cancel"
              textColor="text-white"
              width={16}
              height={3}
            >
              {t(COMMON_TRANSLATE_KEYS.YES)}
            </Button>
            <Button
              onClick={() => closeModal()}
              type="button"
              backgroundColor="bg-transparent"
              border="border"
              borderColor="border-mainBlue"
              textColor="text-mainBlue"
              width={16}
              height={3}
            >
              {t(COMMON_TRANSLATE_KEYS.NO)}
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default ComplexSettingsCard;

import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/24/solid';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Player } from '../../types/players';
import { Reservation } from '../../types/reservations';
import MyDrawer from '../Drawer';
import StatusBadge from '../StatusBadge';
import { DATE_FORMATS, getDateForCalendar } from '../../utils/dates';
import PlayerImage from '../PlayerImage';
import { getPlayerRole } from '../../utils/players';
import { HOURS_MINUTES_REGEX } from '../../utils/regex';
import { COMMON_TRANSLATE_KEYS } from '../../constants/translate-keys';

import TeamLogo from '../../icons/images/team.png';
import NoTeamLogo from '../../icons/images/no-team.png';
import LocationIcon from '../../icons/svg/location.svg';
import PlayerIcon from '../../icons/svg/player.svg';
import ClockIcon from '../../icons/svg/clock.svg';
import Button from '../buttons/Button';
import { acceptReservation, declineReservation } from '../../api/reservations';
import { toast } from 'react-toastify';
import { Dialog } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import InputField from '../InputField';
import ReservationPlayersModal from './ReservationPlayersModal';
import { useResponsiveWindow } from '../../hooks/useResponsiveWindow';
import { useAuth } from '../../hooks/useAuth';

type TProps = {
  isOpen: boolean;
  reservation: Reservation | null;
  onPlayerClick: (player: Player) => void;
  closeDrawer: () => void;
  fetchReservations: () => Promise<void>;
};

interface IFormInput {
  reason: string;
}

const chevronClassName = 'size-4 mx-1';

const ReservationDetailsModal = ({
  isOpen,
  reservation,
  closeDrawer,
  onPlayerClick,
  fetchReservations,
}: TProps) => {
  const [showMore, setShowMore] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openPlayersModal, setOpenPlayersModal] = useState(false);

  const { isMobile } = useResponsiveWindow();

  const user = useAuth();

  const { t, i18n } = useTranslation();

  const { register, getValues } = useForm<IFormInput>({
    defaultValues: {
      reason: '',
    },
  });

  const getPlayTime = (start: string, end: string) => {
    if (start.match(HOURS_MINUTES_REGEX) && end.match(HOURS_MINUTES_REGEX)) {
      return `${start} - ${end}`;
    }

    return `${dayjs(getDateForCalendar(start)).format(
      DATE_FORMATS.HOURS_MINUES
    )} - ${dayjs(getDateForCalendar(end)).format(DATE_FORMATS.HOURS_MINUES)}`;
  };

  const handleAcceptReservation = useCallback(async () => {
    if (!reservation) {
      return;
    }

    const { data, error } = await acceptReservation(reservation.id);

    if (error) {
      const { error: errMsg } = error;

      toast(errMsg, {
        toastId: 'accept-res-error',
        theme: 'dark',
        bodyClassName: 'text-center',
      });

      return;
    }

    if (data) {
      await fetchReservations();
    }

    closeDrawer();
  }, [closeDrawer, fetchReservations, reservation]);

  const handleCancelReservation = useCallback(async () => {
    if (!reservation) {
      setOpenCancelModal(false);
      return;
    }

    const payload = {
      reason: getValues('reason'),
    };

    const { data, error } = await declineReservation(reservation.id, payload);

    if (error) {
      const { error: errMsg } = error;

      toast(errMsg, {
        toastId: 'accept-res-error',
        theme: 'dark',
        bodyClassName: 'text-center',
      });

      return;
    }

    if (data) {
      await fetchReservations();
    }

    setOpenCancelModal(false);
    closeDrawer();
  }, [fetchReservations, reservation, getValues, closeDrawer]);

  const renderModalContent = useCallback(() => {
    if (!reservation) {
      return <></>;
    }
    const {
      team,
      field,
      date,
      start,
      end,
      status,
      isGamePublic,
      creator,
      otherName,
      otherPhone,
    } = reservation;

    if (team) {
      return (
        <div className="h-full overflow-y-auto">
          <div className="flex justify-between">
            <StatusBadge status={status} className="m-0 ml-0 mb-4" />
            <button onClick={closeDrawer} className="text-white">
              X
            </button>
          </div>
          <div className="bg-additionalBlack rounded-lg h-full">
            <div className="flex">
              <img
                src={team.logoUrl || TeamLogo}
                alt="Team"
                className="object-cover w-full h-[240px] rounded-lg"
              />
            </div>
            <div className="flex flex-col p-3 gap-2">
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setOpenPlayersModal(true);
                }}
                className="font-inter text-2xl not-italic font-bold leading-7 mb-3 border-0 bg-transparent text-start hover:underline"
              >
                {`${t(COMMON_TRANSLATE_KEYS.TEAM)} ${team.name}`}
              </button>
              <div>
                <div className="flex flex-row items-start gap-1">
                  <span className="flex gap-1">
                    <img src={LocationIcon} alt="Location" />
                    {`${t(COMMON_TRANSLATE_KEYS.ADDRESS)}:`}
                  </span>
                  <span>
                    <p>{`${[field.city, field.district]
                      .filter((x) => x)
                      .join(', ')},`}</p>
                  </span>
                </div>
                <p className="text-midGray ml-5">{`${field.address}`}</p>
              </div>
              <div className="flex flex-row items-start gap-2">
                <img className="mt-1" src={PlayerIcon} alt="Players" />
                <p>{`${t(COMMON_TRANSLATE_KEYS.PLAYERS, {
                  count: Number(team.players.length),
                })}: ${team.players.length}`}</p>
              </div>
              <div className="flex flex-row items-start gap-2">
                <img className="mt-1" src={ClockIcon} alt="Clock" />
                <p>{`${t(COMMON_TRANSLATE_KEYS.DATE)}: ${
                  date
                    ? dayjs(date)
                        .locale(i18n.language)
                        .format(DATE_FORMATS.WEEK_FIRST)
                    : t(COMMON_TRANSLATE_KEYS.RECURRING)
                } (${getPlayTime(start, end)})`}</p>
              </div>
              <div className="flex flex-row">
                <p className="text-mainBlue">
                  {`${t(COMMON_TRANSLATE_KEYS.COMPLEX)}:`}{' '}
                  <span className="text-white">{field.complexName}</span>
                </p>
              </div>
              <div className="flex flex-row">
                <p className="text-mainBlue">
                  {`${t(COMMON_TRANSLATE_KEYS.FIELD)}:`}{' '}
                  <span className="text-white">{field.name}</span>
                </p>
              </div>
              <div className="flex flex-row">
                <p className="text-mainBlue">
                  {t(COMMON_TRANSLATE_KEYS.OWNER)}/
                  {t(COMMON_TRANSLATE_KEYS.CAPTAIN)}:{' '}
                  <span className="text-white">{team.captain.name}</span>
                </p>
              </div>
              {team.secondCaptain && (
                <div className="flex flex-row">
                  <p className="text-mainBlue">
                    {t(COMMON_TRANSLATE_KEYS.SECOND_CAPTAIN)}:{' '}
                    <span className="text-white">
                      {team.secondCaptain.name}
                    </span>
                  </p>
                </div>
              )}
              <div className="flex flex-row">
                <p className="text-mainBlue">
                  {t(COMMON_TRANSLATE_KEYS.CONTACT_CREATOR)}:{' '}
                  <span className="text-white">
                    {reservation.creator.phone}
                  </span>
                </p>
              </div>
              {reservation.status === 'CONFIRMED' && (
                <Button
                  className="h-full mt-5"
                  textColor="text-red-500"
                  borderColor="border-red-500"
                  border="border"
                  onClick={() => setOpenCancelModal(true)}
                >
                  {t(COMMON_TRANSLATE_KEYS.CANCEL)}
                </Button>
              )}
              {reservation.status === 'PENDING' && (
                <div className="flex flex-row justify-around gap-x-2 mt-5">
                  <Button
                    backgroundColor="bg-mainGreen"
                    textColor="text-mainBlack"
                    width={isMobile ? undefined : 30}
                    onClick={async () => await handleAcceptReservation()}
                  >
                    {t(COMMON_TRANSLATE_KEYS.ACCEPT)}
                  </Button>
                  <Button
                    backgroundColor="bg-functionalRed"
                    textColor="text-mainWhite"
                    width={isMobile ? undefined : 30}
                    onClick={() => setOpenCancelModal(true)}
                  >
                    {t(COMMON_TRANSLATE_KEYS.CANCEL)}
                  </Button>
                </div>
              )}
              {/* <div className="mt-4 mb-8">
                <div className="flex flex-row justify-between">
                  <p className="text-lg font-semibold">
                    {t(COMMON_TRANSLATE_KEYS.PLAYERS, { count: 2 })}
                  </p>
                  <button
                    className={`inline-flex items-center ${
                      team.players.length < 5 && 'hidden'
                    }`}
                    disabled={team.players.length < 5}
                    onClick={() => {
                      console.log('show more clicked');
                      setShowMore((prev) => !prev);
                    }}
                  >
                    {t(COMMON_TRANSLATE_KEYS.SEE_MORE)}{' '}
                    {showMore ? (
                      <ChevronDownIcon className={chevronClassName} />
                    ) : (
                      <ChevronUpIcon className={chevronClassName} />
                    )}
                  </button>
                </div>
                <div className="grid grid-cols-4 gap-4 mt-4">
                  {team.players.map((player, index) => (
                    <PlayerImage
                      key={index}
                      name={player.name}
                      role={getPlayerRole(player, team)}
                      imageUrl={player.imageUrl}
                      onClick={() => onPlayerClick(player)}
                      hidden={!showMore && index > 3}
                    />
                  ))}
                </div>
              </div> */}
            </div>
          </div>
        </div>
      );
    }

    // const otherPersonNameAndPhone =
    //   otherName && otherPhone ? `${otherName} (${otherPhone})` : '';

    return (
      <div className="h-full overflow-y-auto">
        <div className="flex justify-between">
          <StatusBadge status={status} className="m-0 ml-0 mb-4" />
          <button onClick={closeDrawer} className="text-white">
            X
          </button>
        </div>
        <div className="bg-additionalBlack rounded-lg">
          <div className="flex">
            <img
              src={NoTeamLogo}
              alt="Team"
              className="object-cover w-full h-[240px]"
            />
          </div>
          <div className="flex flex-col p-3 gap-2">
            <h2 className="font-inter text-2xl not-italic font-bold leading-7 mb-3">
              {isGamePublic
                ? t(COMMON_TRANSLATE_KEYS.PUBLIC_GAME)
                : t(COMMON_TRANSLATE_KEYS.PRIVATE_GAME)}
            </h2>
            <div>
              <div className="flex flex-row items-start gap-1">
                <span className="flex gap-1">
                  <img src={LocationIcon} alt="Location" />
                  {`${t(COMMON_TRANSLATE_KEYS.ADDRESS)}:`}
                </span>
                <span>
                  <p>{`${[field.city, field.district]
                    .filter((x) => x)
                    .join(', ')},`}</p>
                </span>
              </div>
              <p className="text-midGray ml-5">{`${field.address}`}</p>
            </div>
            <div className="flex flex-row items-start gap-2">
              <img src={PlayerIcon} alt="Players" />
              <p>{`${t(COMMON_TRANSLATE_KEYS.PLAYERS, {
                count: Number(field.playerSize.size),
              })}: ${field.playerSize.size}`}</p>
            </div>
            <div className="flex flex-row items-start gap-2">
              <img className="mt-1" src={ClockIcon} alt="Clock" />
              <p>{`${t(COMMON_TRANSLATE_KEYS.DATE)}: ${
                date
                  ? dayjs(date)
                      .locale(i18n.language)
                      .format(DATE_FORMATS.WEEK_FIRST)
                  : t(COMMON_TRANSLATE_KEYS.RECURRING)
              } (${getPlayTime(start, end)})`}</p>
            </div>
            <div className="flex flex-row">
              <p className="text-mainBlue">
                {`${t(COMMON_TRANSLATE_KEYS.COMPLEX)}:`}{' '}
                <span className="text-white">{field.complexName}</span>
              </p>
            </div>
            <div className="flex flex-row">
              <p className="text-mainBlue">
                {`${t(COMMON_TRANSLATE_KEYS.FIELD)}:`}{' '}
                <span className="text-white">{field.name}</span>
              </p>
            </div>
            <div className="flex flex-row">
              <p className="text-mainBlue">
                {t(COMMON_TRANSLATE_KEYS.OWNER)}/
                {t(COMMON_TRANSLATE_KEYS.CAPTAIN)}:{' '}
                <span className="text-white">
                  {otherName ||
                    `${creator.name} ${
                      creator.id === user.user?.id
                        ? `(${t(COMMON_TRANSLATE_KEYS.YOU)})`
                        : creator.name
                    }`}
                </span>
              </p>
            </div>
            <div className="flex flex-row">
              <p className="text-mainBlue">
                {t(COMMON_TRANSLATE_KEYS.CONTACT_CREATOR)}:{' '}
                <span className="text-white">
                  {otherPhone || creator.phone}
                </span>
              </p>
            </div>
            {reservation.status === 'CONFIRMED' && (
              <Button
                className="h-full mt-5"
                textColor="text-red-500"
                borderColor="border-red-500"
                border="border"
                onClick={() => setOpenCancelModal(true)}
              >
                {t(COMMON_TRANSLATE_KEYS.CANCEL)}
              </Button>
            )}
            {reservation.status === 'PENDING' && (
              <div className="flex flex-row justify-around gap-x-2 mt-5">
                <Button
                  backgroundColor="bg-mainGreen"
                  textColor="text-mainBlack"
                  width={isMobile ? undefined : 30}
                  onClick={async () => await handleAcceptReservation()}
                >
                  {t(COMMON_TRANSLATE_KEYS.ACCEPT)}
                </Button>
                <Button
                  backgroundColor="bg-functionalRed"
                  textColor="text-mainWhite"
                  width={isMobile ? undefined : 30}
                  onClick={() => setOpenCancelModal(true)}
                >
                  {t(COMMON_TRANSLATE_KEYS.CANCEL)}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }, [
    reservation,
    closeDrawer,
    t,
    i18n.language,
    user.user?.id,
    isMobile,
    handleAcceptReservation,
  ]);

  return (
    <>
      <MyDrawer isOpen={isOpen}>{renderModalContent()}</MyDrawer>

      <ReservationPlayersModal
        isOpen={openPlayersModal}
        onClose={() => setOpenPlayersModal(false)}
        team={reservation?.team}
        onPlayerClick={onPlayerClick}
      />

      <Dialog
        open={openCancelModal}
        onClose={() => setOpenCancelModal(false)}
        className="fixed inset-0 flex items-center justify-center z-50"
      >
        <Dialog.Overlay className="fixed inset-0 bg-black  opacity-50" />
        <div className="bg-additionalBlack p-6 rounded-xl absolute top-212 left-468 lg:max-w-[40%] mx-6">
          <div className="flex flex-row justify-between mb-3">
            <Dialog.Title className="text-white font-bold text-2xl">
              {t(COMMON_TRANSLATE_KEYS.CANCEL_RESERVATION_MODAL_TITLE)}
            </Dialog.Title>
            <button
              className="ps-3 pb-3 text-white"
              onClick={() => setOpenCancelModal(false)}
            >
              X
            </button>
          </div>
          <div className="w-full">
            <p className="text-white text-sm mt-6 mb-4">
              {t(COMMON_TRANSLATE_KEYS.DECLINE_REASON)} (
              {t(COMMON_TRANSLATE_KEYS.OPTIONAL)})
            </p>
          </div>
          <div className="mb-6">
            <InputField
              {...register('reason')}
              placeholder={'Enter text'}
              height={3}
            />
          </div>
          <div className="flex justify-center">
            <Button
              textColor="text-white"
              backgroundColor="bg-functionalRed"
              width={16}
              height={3}
              onClick={async () => await handleCancelReservation()}
            >
              {t(COMMON_TRANSLATE_KEYS.CANCEL)}
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default ReservationDetailsModal;

import { useEffect } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../hooks/useAuth';
import Navbar from './Navbar';
import Sidebar from './sidebar/Sidebar';
import { onMessage } from 'firebase/messaging';
import { messaging } from '../services/firebase';
import NotificationToast from './notifications/NotificationToast';
import { useNotifications } from '../hooks/notifications/useNotifications';
import { useUnreadNotifications } from '../hooks/notifications/useUnreadNotifications';
import { AppNotification } from '../types/notifications';
import { usePageVisibility } from '../hooks/usePageVisibility';
import CollapsedSidebar from './sidebar/CollapsedSidebar';
import { useResponsiveWindow } from '../hooks/useResponsiveWindow';

const parseNotificationData = (data?: { [key: string]: string }) => {
  if (!data) {
    return data;
  }

  return Object.keys(data).reduce<any>((cur, k) => {
    cur[k] = JSON.parse(data[k]);

    return cur;
  }, {});
};

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const { isTablet } = useResponsiveWindow();

  const { setNotifications, getNotifications, fetched } = useNotifications();

  const {
    setUnreadNotificationsCount,
    getUnreadNotificationsCount,
    fetched: fetchedUnread,
  } = useUnreadNotifications();

  const { isVisible } = usePageVisibility();

  // Fetch the notifications when the user opens the Bolarena tab in the browser and is logged in
  useEffect(() => {
    if (isAuthenticated() && isVisible) {
      Promise.all([getNotifications(), getUnreadNotificationsCount()]);
    }
  }, [
    getNotifications,
    getUnreadNotificationsCount,
    isAuthenticated,
    isVisible,
  ]);

  // Fetch the notifications after the user logs in
  useEffect(() => {
    if (isAuthenticated() && !fetched && !fetchedUnread) {
      Promise.all([getNotifications(), getUnreadNotificationsCount()]);
    }
  }, [
    isAuthenticated,
    getNotifications,
    getUnreadNotificationsCount,
    fetched,
    fetchedUnread,
  ]);

  onMessage(messaging, (payload) => {
    const { title, body } = payload.notification || {};

    const notificationData = parseNotificationData(payload?.data);

    toast(
      <NotificationToast
        title={title || ''}
        body={body || ''}
        notificationData={notificationData}
      />,
      {
        toastId: payload.messageId,
      }
    );

    // TODO: Refactor this
    setNotifications([
      { title, body, status: 'PENDING', ...notificationData },
    ] as AppNotification[]);
    setUnreadNotificationsCount(1);
  });

  return (
    <>
      <div className="flex sm:h-screen h-full min-h-screen w-screen overflow-hidden ">
        {isAuthenticated() && <Sidebar />}
        <div className="flex flex-col flex-1 bg-mainBlack overflow-y-auto min-h-screen h-full">
          {isAuthenticated() &&
            (isTablet ? <CollapsedSidebar defaultOpen /> : <Navbar />)}
          {children}
        </div>
      </div>
    </>
  );
};

export default Wrapper;

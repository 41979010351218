import React, {
  forwardRef,
  useCallback,
} from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import ImageCarousel from "./carousel/ImageCarousel";
import { TImage } from "../types";

interface DropzoneProps {
  addImages: (images: TImage[]) => void;
  removeImage: (image: TImage) => void;
  images?: Array<TImage>; 
}

// Previously used
export interface DropzoneImperativeHandle {
  clearImages: () => void;
}

const showDuplicateFileToast = (msg: string) => {
  toast(msg, {
    toastId: 'duplicate-file',
  });
};

const Dropzone: React.FC<DropzoneProps> = forwardRef(({
  addImages,
  removeImage,
  images = []
}, ref) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        let duplicateFiles = 0;

        for (let index = 0; index < acceptedFiles.length; index++) {
          const currentFile = acceptedFiles[index];
          const fileExists = images.find((img) => img.id === currentFile.name);
          
          if (fileExists) {
            duplicateFiles++;
            continue;
          }

          const reader = new FileReader();

          reader.onload = () => {
            const imageData = reader.result as string;

            const image: TImage = {
              id: currentFile.name,
              src: imageData,
              file: currentFile,
            };

            addImages([image]);
          };
          
          reader.readAsDataURL(currentFile);
        }

        if (duplicateFiles === 1) {
          showDuplicateFileToast('One file was already added');
        } else if (duplicateFiles > 1) {
          showDuplicateFileToast('One or more files were already added');
        }
      }
    },
    [addImages, images],
  );

  const onImageRemove = useCallback((image: TImage) => {
    removeImage(image);
  }, [removeImage]);

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    maxSize: 10485760, // 10MB
    multiple: true,
  });

  const dropzoneStyle = {
    height: "300px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    fontSize: "16px",
  };

  return (
    <>
      <div className="sm:w-[594px] w-full">
        <div
          {...getRootProps()}
          style={dropzoneStyle}
          className="bg-backGroundGray max-w-[594px] w-full sm:text-start text-center px-3"
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>{t(COMMON_TRANSLATE_KEYS.DROP_HERE)}</p>
          ) : isDragReject ? (
            <p>{t(COMMON_TRANSLATE_KEYS.UPLOAD_FAILED)}</p>
          ) : (
            <p>{t(COMMON_TRANSLATE_KEYS.DRAG_AND_DROP)}</p>
          )}
        </div>
        {images.length > 0 && (
          <ImageCarousel onRemoveItem={onImageRemove} images={images} itemClassName="w-full max-w-[594px] sm:h-[200px] h-[300px] p-5" />
        )}
      </div>

    </>
  );
});

export default Dropzone;

import React, { forwardRef, useState } from "react";

import { ReactComponent as IconEye } from '../icons/svg/IconEye.svg';

interface InputFieldProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  placeholder?: string;
  value?: string;
  width?: number;
  height?: number;
  type?: string;
  error?: string;
}

const InputField: React.FC<InputFieldProps> = forwardRef<HTMLInputElement, InputFieldProps>(({
  placeholder,
  value,
  onChange,
  width,
  height = 3,
  type = "text",
  error,
  disabled,
  ...rest
}, ref) => {
  const [inputType, setInputType] = useState(type);

  const getInputClassName = () => {
    let className = 'relative rounded-2xl text-sm py-4 pl-4 placeholder-mainBlue placeholder-opacity-50';
    if (error) {
      className = className + ' border-cancel border';
    }

    if (disabled) {
      className = className + ' bg-gray-800 text-gray-400';
    } else {
      className = className + ' bg-backGroundGray text-mainBlue'
    }

    return className;
  }

  const showOrHidePassword = () => {
    setInputType((prev) => {
      if (prev === 'password') {
        return 'text';
      } else {
        return 'password';
      }
    })
  }

  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col relative text-mainBlue w-full">
        <input
          ref={ref}
          type={inputType}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          className={getInputClassName()}
          style={{ maxWidth: `${width}rem`, height: `${height}rem` }}
          disabled={disabled}
          {...rest}
        />
        {type === 'password' && <IconEye className="absolute top-[30%] right-[15px] origin-[-15%_-50%]" onClick={() => showOrHidePassword()} />}
      </div>
      {error && <span className="text-cancel font-inter text-[12px] leading-5 w-full">{error}</span>}
    </div>
  );
});

export default InputField;

import React from 'react';
import { Menu } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { DropdownMenuProps, MenuItem } from '../types';
import { useTranslation } from 'react-i18next';

const DropdownMenu = <T,>({
  items,
  selectedOption,
  setSelectedOption,
  showIcon = true,
  width,
  height = 3,
  placeholder,
  transparent,
  customLabel,
  isIconAdjacent,
  error,
}: DropdownMenuProps<T>) => {
  const { t } = useTranslation();
  const handleOptionClick = async (option: MenuItem<T>) => {
    setSelectedOption(option);
    const value = option.value;

    if (option.onClick) {
      await option.onClick();
    }

    return value;
  };

  const isSelectedOption = (item: MenuItem<T>) => {
    const { value } = item;

    if (typeof value === 'object') {
      return (selectedOption?.value as any)?.id === (value as any)?.id;
    }

    return selectedOption?.value === value;
  };

  const getMenuButtonClassName = () => {
    let className =
      'flex items-center text-mainWhite font-semibold bg-backGroundGray rounded-2xl px-4 w-full';

    if (transparent) {
      className += ' text-lg py-0';
    } else {
      className += ' text-base py-2';
    }

    if (isIconAdjacent) {
      className += ' justify-start';
    } else {
      className += ' justify-between';
    }

    return className;
  };

  const getButtonClassName = (item: MenuItem<T>, active: boolean) => {
    let className =
      'group flex flex-col items-start w-full p-2 text-sm text-start';

    if (active) {
      className += ' bg-mainBlack text-mainWhite';
    } else if (isSelectedOption(item)) {
      className += ' bg-dividerGray';
    }

    if (item.className) {
      className += ` ${item.className}`;
    }

    return className;
  };

  const getLabelClassName = () => {
    let className = 'mr-1 leading-22.4px font-inter font-medium';

    if (transparent) {
      className += ' text-base inline-flex';
    } else {
      className += ' text-sm';
    }

    return className;
  };

  return (
    <Menu as="div" className="relative w-full min-w-52">
      <Menu.Button
        style={{
          maxWidth: width ? `${width}rem` : '100%',
          height: `${height}rem`,
          backgroundColor: transparent ? 'transparent' : undefined,
        }}
        className={getMenuButtonClassName()}
      >
        {showIcon && (
          <img
            src="/icons/settings-highlighted.svg"
            alt="Settings"
            className="w-8 h-8 mr-4"
          />
        )}
        <span className={getLabelClassName()}>
          {customLabel || selectedOption
            ? selectedOption?.label
            : placeholder || t('select_an_option')}
        </span>
        <ChevronDownIcon className=" w-4 h-4 text-mainWhite" />
      </Menu.Button>
      <Menu.Items
        className="absolute right-0 mt-2 origin-top-right bg-backGroundGray divide-y divide-dividerGray rounded-2xl text-mainWhite z-50"
        style={{ width: `100%`, maxHeight: '10rem', overflowY: 'auto' }}
      >
        {items.map((item, index) => (
          <Menu.Item key={index}>
            {({ active }) => (
              <button
                className={getButtonClassName(item, active)}
                onClick={() => handleOptionClick(item)}
                disabled={item.isDisabled}
              >
                {item.label}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
      {error && (
        <span className="text-cancel font-inter text-[12px] leading-5 w-full">
          {error}
        </span>
      )}
    </Menu>
  );
};

export default DropdownMenu;

/* eslint-disable jsx-a11y/anchor-has-content */
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import InputField from "../components/InputField";
import Button from "../components/buttons/Button";
import GoogleButton from "../components/buttons/link-buttons/google-btn";
import { Link, Navigate, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import { ROUTE_FORGOT_PASS, ROUTE_MY_FIELDS, ROUTE_REGISTER } from "../router/constants";
import { forgotPassword, login as loginRequest } from "../api/auth";
import DarkenBackground from "../components/DarkenBackground";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import SwitchLanguageButton from "../components/SwitchLanguageButton";

interface IFormInput {
  email: string;
  password: string;
}

const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  let [queryParams, setQueryParams] = useSearchParams();

  const { login, isAuthenticated } = useAuth();

  const location = useLocation();

  const { t } = useTranslation();

  const { register, handleSubmit, reset, setError, getValues, formState: { errors, isValid, isSubmitted, isLoading: isFormSending } } = useForm<IFormInput>({
    defaultValues: {
      email: location.state?.email || '',
      password: '',
    },
  });

  const navigate = useNavigate();

  const error = queryParams.get('error');

  const handleLogin = async (input: IFormInput) => {
    try {
      const { data, error: requestError } = await loginRequest(input);

      if (requestError) {
        setError('password', { type: 'custom', message: requestError.error.message || COMMON_TRANSLATE_KEYS.INCORRECT_CREDS });
        return;
      }

      if (data) {
        await login(data.accessToken, data.user, true);
        navigate(ROUTE_MY_FIELDS, { replace: true });
      }

      reset({}, {
        keepErrors: true,
        keepDirty: true,
      });
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    // Show error in toast and delete query
    if (error) {
      toast(error, {
        toastId: 'google-auth-error',
      });

      setQueryParams((prev) => {
        prev.delete('error');

        return prev;
      });
    }
  }, [error, setQueryParams]);

  const handleForgotPasswordClick = useCallback(async () => {
    if (loading || isFormSending) {
      return;
    }

    const email = getValues('email');

    setLoading(true);

    const { data, error } = await forgotPassword({
      email,
    });

    setLoading(false);

    if (error) {
      const { error: errMsg } = error;

      if (errMsg === 'Owner not found') {
        toast(t(COMMON_TRANSLATE_KEYS.OWNER_NOT_FOUND), {
          toastId: 'owner-not-found',
          theme: 'dark',
          bodyClassName: 'text-center'
        });
        return;
      }
    
      toast(errMsg, {
        toastId: 'reset-pass-error',
        theme: 'dark',
        bodyClassName: 'text-center'
      });
      return;
    }

    if (data) {
      if (!data.success) {
        toast(t(COMMON_TRANSLATE_KEYS.EMAIL_SENDING_ERROR), {
          toastId: 'reset-pass-error',
          theme: 'dark',
          bodyClassName: 'text-center'
        });
        return;
      } else {
        navigate(ROUTE_FORGOT_PASS, {
          state: {
            email,
            sent: Date.now(),
          },
        });
      }
    }
  }, [getValues, navigate, isFormSending, loading, t]);


  if (isAuthenticated()) {
    return <Navigate to={ROUTE_MY_FIELDS} replace />
  }

  return (
    <div className="relative min-h-screen h-full py-5 sm:py-0 flex justify-center overflow-auto">
      <DarkenBackground />

      <div className="z-20 justify-center sm:w-[386px] max-w-[386px] w-full flex flex-col items-center">
        <div className="flex flex-col justify-center items-center mb-8">
          <h1 className="text-3xl text-white font-semibold font-montserrat">
            {t(COMMON_TRANSLATE_KEYS.WELCOME)}
          </h1>

          <h3 className="text-[30px] text-gray-500 font-light font-montserrat">
            {t(COMMON_TRANSLATE_KEYS.TO_BOLARENA)}
          </h3>
        </div>
        <form onSubmit={handleSubmit(handleLogin)} className="flex flex-col justify-center mb-8 gap-4 px-3 w-full">
          <InputField
            {...register('email', { required: true })}
            placeholder={t(COMMON_TRANSLATE_KEYS.EMAIL)}
            width={38}
          />

          <InputField
            {...register('password', { required: true })}
            placeholder={t(COMMON_TRANSLATE_KEYS.PASSWORD)}
            type="password"
            width={38}
            error={errors.password?.message}
          />

          {isSubmitted && (
            <p
              className="flex flex-row justify-center text-white text-sm mt-4 cursor-pointer"
              onClick={async () => await handleForgotPasswordClick()}
            >
              {t(COMMON_TRANSLATE_KEYS.FORGOT_PASSWORD)}?
            </p>
          )}

          <Button
            type="submit"
            backgroundColor="bg-mainGreen"
            textColor="text-black-300"
            isDisabled={!isValid || isFormSending || loading}
          >
            {t(COMMON_TRANSLATE_KEYS.LOGIN)}
          </Button>

          <div className="justify-center pt-2 pb-4">
            <div className="flex items-center">
              <hr className="inline-flex flex-1" />
              <p className="text-white text-sm inline-flex px-3 my-0">{t(COMMON_TRANSLATE_KEYS.OR_LOGIN_WITH)}</p>
              <hr className="inline-flex flex-1" />
            </div>
          </div>

          <div className="flex row col-span-3 justify-center">
            <GoogleButton width={8} backgroundColor="bg-white" isDisabled={isFormSending || loading} />
          </div>
        </form>

        <p className="text-white mb-3">{`${t(COMMON_TRANSLATE_KEYS.NO_ACCOUNT_CURRENTLY)}?`} <Link to={ROUTE_REGISTER} className="text-mainGreen">{t(COMMON_TRANSLATE_KEYS.SIGN_UP_NOW)}</Link></p>

        <SwitchLanguageButton />
      </div>
    </div>
  );
};

export default LoginPage;

import React, { ReactNode, useMemo } from 'react';
import ImageCarousel from '../carousel/ImageCarousel';
import { Complex, Field } from '../../types';
import { getFileNameFromUrl } from '../../utils/files';
import { COMMON_TRANSLATE_KEYS } from '../../constants/translate-keys';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../hooks/useAuth';

interface FieldCardProps {
  title: string;
  location: string;
  children: ReactNode;
  width?: 'auto' | 'sm' | 'md' | 'lg' | 'xl';
  id?: number;
  field: Field;
  complex: Complex;
}

const FieldCard: React.FC<FieldCardProps> = ({
  title,
  location,
  children,
  width = '',
  field,
  complex,
  id,
}) => {
  const { t } = useTranslation();

  const { user } = useAuth();

  const getWidthClass = (width: string) => {
    switch (width) {
      case 'sm':
        return 'w-40';
      case 'md':
        return 'w-96';
      case 'lg':
        return 'lg:w-[49%] w-full';
      case 'xl':
        return 'w-full';
      default:
        return '';
    }
  };

  const carouselImages = useMemo(() => {
    return (
      field?.imageUrls.map((src) => ({
        id: getFileNameFromUrl(src, 'field'),
        src: src,
      })) || []
    );
  }, [field]);

  return (
    <div
      className={`relative overflow-hidden rounded-2xl ${getWidthClass(
        width
      )} bg-secondaryBlack`}
    >
      {width === 'xl' ? (
        <>
          <div
            className={`relative rounded-lg bg-secondaryBlack shadow-lg ${getWidthClass(
              width
            )}`}
          >
            {carouselImages.length > 0 && (
              <div className="relative flex flex-col">
                <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-secondaryBlack via-semi-transparent to-transparent z-10" />
                <ImageCarousel
                  images={carouselImages}
                  containerClassName="sm:relative absolute"
                  itemClassName="w-full h-[300px] p-3"
                  showDots={false}
                  readonly
                />
              </div>
            )}
            <div
              className="flex flex-col h-full sm:items-start items-center"
              style={{ paddingTop: carouselImages.length === 0 ? '300px' : 0 }}
            >
              <h2 className="text-6xl text-mainWhite sm:text-start text-center font-bold sm:pl-4">
                {`${t(COMMON_TRANSLATE_KEYS.FIELD)} ${title}`}
              </h2>
              <div className="p-4 text-mainWhite flex sm:flex-row flex-col justify-between gap-5 w-full">
                <span className="text-base sm:text-start text-center sm:max-w-[700px] flex flex-col gap-1">
                  <p>{`${t(COMMON_TRANSLATE_KEYS.COMPLEX)}: ${complex.name}`}</p>
                  <p>{`${t(COMMON_TRANSLATE_KEYS.OWNER)}: ${user?.name}`}</p>
                  <p>{`${t(COMMON_TRANSLATE_KEYS.PHONE)}: ${user?.phone}`}</p>
                </span>
                {children}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="relative w-full h-[250px]">
            {carouselImages.length > 0 && (
              <div className="relative overflow-hidden rounded-t-lg">
                <ImageCarousel
                  images={carouselImages}
                  containerClassName="absolute"
                  itemClassName="h-[250px]"
                  itemsShown={{
                    smallDesktop: 1,
                    superLargeDesktop: 2,
                    desktop: 1,
                    tablet: 1,
                  }}
                  showDots={false}
                  readonly
                />
                <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-secondaryBlack via-semi-transparent to-transparent z-10" />
              </div>
            )}
          </div>
          <div className="p-4 text-mainWhite flex flex-col">
            <div className="flex flex-row justify-between mb-3">
              <div>
                <h2 className="text-xl font-bold mb-2 ">{title}</h2>
                <p className="text-sm mb-2">{location}</p>
              </div>
            </div>
            <div className="flex flex-row justify-center">{children}</div>
          </div>
        </>
      )}
    </div>
  );
};

export default FieldCard;

interface Props {
  className?: string;
}

const Logo = ({ className }: Props) => {
  return (
    <div className={`flex flex-row items-center ${className}`}>
      <img src="/icons/logo.svg" alt="Logo" />
      <div className="text-xl hover:cursor-pointer">bolarena</div>
    </div>
  );
};

export default Logo;
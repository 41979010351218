import { CheckCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { ReactComponent as PendingIcon } from '../icons/svg/pending.svg';
import { CSSProperties, useCallback } from 'react';
import { COMMON_TRANSLATE_KEYS } from '../constants/translate-keys';
import { useTranslation } from 'react-i18next';

interface TProps {
  status: string;
  count?: number;
  className?: string;
  style?: CSSProperties;
  dontShrink?: boolean;
  confirmedStatusKey?: string;
}

const StatusBadge = ({
  status,
  className,
  style,
  count = 1,
  dontShrink,
  confirmedStatusKey,
}: TProps) => {
  const { t } = useTranslation();

  const renderStatusBadge = useCallback(() => {
    let statusColor: string = 'gray';
    let borderColor: string = 'gray';
    let statusKey: string = status;
    let IconComponent = null;
    let responsiveDisplay = 'hidden xl:flex';

    switch (status) {
      case 'CONFIRMED':
        statusColor = 'text-mainGreen';
        borderColor = 'border-mainGreen';
        IconComponent = CheckCircleIcon;
        statusKey = confirmedStatusKey || COMMON_TRANSLATE_KEYS.ACCEPTED;
        break;
      case 'DECLINED':
        statusColor = 'text-functionalRed';
        borderColor = 'border-functionalRed';
        IconComponent = XMarkIcon;
        statusKey = COMMON_TRANSLATE_KEYS.DECLINED;
        break;
      case 'PENDING':
        statusColor = 'text-mainBlue';
        borderColor = 'border-mainBlue';
        IconComponent = PendingIcon;
        statusKey = COMMON_TRANSLATE_KEYS.PENDING;
        break;
      case 'CANCELED':
        statusColor = 'text-functionalRed';
        borderColor = 'border-functionalRed';
        IconComponent = XMarkIcon;
        statusKey = COMMON_TRANSLATE_KEYS.CANCELED;
        break;
      default:
        statusKey = COMMON_TRANSLATE_KEYS.UNKNOWN;
    }

    if (dontShrink) {
      responsiveDisplay = 'flex';
    }

    return (
      <div
        className={`flex items-center ml-6 m-4 mt-4 py-1 px-3 border justify-center gap-2 rounded-xl ${borderColor} ${className}`}
        style={style}
      >
        {IconComponent && (
          <IconComponent className={`h-5 w-full ${statusColor}`} />
        )}
        <span className={`${statusColor} flex-shrink-0 ${responsiveDisplay}`}>
          {t(statusKey, { count })}
        </span>
      </div>
    );
  }, [status, className, style, count, dontShrink, t]);

  return renderStatusBadge();
};

export default StatusBadge;

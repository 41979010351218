import { useMemo } from 'react';
import Carousel, { ResponsiveType } from 'react-multi-carousel';
import { TImage } from '../../types';

import './ImageCarousel.css';

type TProps = {
  images: TImage[];
  readonly?: boolean;
  onRemoveItem?: (image: TImage) => void;
  itemClassName?: string;
  containerClassName?: string;
  itemsShown?: {
    superLargeDesktop?: number;
    desktop?: number;
    tablet?: number;
    mobile?: number;
    smallDesktop?: number;
  };
  showDots?: boolean;
};

const ImageCarousel = ({
  images,
  onRemoveItem,
  readonly,
  containerClassName,
  itemsShown,
  itemClassName = '',
  showDots = true,
}: TProps) => {
  const responsive = useMemo<ResponsiveType>(() => {
    const xlDesktopItems = itemsShown?.superLargeDesktop || 5;
    const desktopItems = itemsShown?.desktop || 3;
    const smallDesktopItems = itemsShown?.smallDesktop || 2;
    const tabletItems = itemsShown?.tablet || 2;
    const mobileItems = itemsShown?.mobile || 1;

    return {
      superLargeDesktop: {
        breakpoint: { max: 4000, min: 2000 },
        items: images.length > xlDesktopItems ? xlDesktopItems : images.length,
      },
      desktop: {
        breakpoint: { max: 2000, min: 1600 },
        items: images.length > desktopItems ? desktopItems : images.length,
      },
      smallDesktop: {
        breakpoint: { max: 1600, min: 1024 },
        items: images.length > smallDesktopItems ? smallDesktopItems : images.length,
      },
      tablet: {
        breakpoint: { max: 1024, min: 651 },
        items: images.length > tabletItems ? tabletItems : images.length,
      },
      mobile: {
        breakpoint: { max: 651, min: 0 },
        items: mobileItems,
      },
    };
  }, [
    itemsShown?.desktop,
    itemsShown?.smallDesktop,
    itemsShown?.mobile,
    itemsShown?.superLargeDesktop,
    itemsShown?.tablet,
    images,
  ]);

  return (
    <Carousel
      responsive={responsive}
      slidesToSlide={1}
      className="w-full"
      itemClass="w-auto"
      containerClass={containerClassName}
      showDots={showDots}
      arrows
    >
      {images.map((i) => (
        <div key={i.id} className={'relative ' + itemClassName}>
          {!readonly && (
            <button
              onClick={() => onRemoveItem && onRemoveItem(i)}
              className="absolute top-1 right-1 bg-red-500 text-white rounded-full w-[25px] h-[26px]"
            >
              x
            </button>
          )}
          <img
            className="object-cover h-full w-full rounded-md"
            src={i.src}
            alt={i.id}
          />
        </div>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;

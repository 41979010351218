import React, { useCallback } from "react";

interface ButtonProps {
  width?: number;
  height?: number;
  backgroundColor?: string;
  textColor?: string;
  border?: string;
  borderColor?: string;
  children: React.ReactNode;
  isDisabled?: boolean;
  onClick?: () => void;
  type?: "submit" | "reset" | "button" | undefined;
  className?: string;
  IconBefore?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  IconAfter?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
}

const Button: React.FC<ButtonProps> = ({
  width,
  height,
  backgroundColor = "bg-transparent",
  textColor = "text-mainBlue",
  border = "",
  borderColor = "",
  type,
  children,
  isDisabled = false,
  className,
  onClick,
  IconBefore,
  IconAfter,
}) => {
  const borderClass = border ? `${border} ${borderColor} border` : "";

  const getClassName = useCallback(() => {
    let fullClassName = ['px-4 py-2 rounded-2xl text-center'];

    if (isDisabled) {
      fullClassName.push('cursor-not-allowed opacity-50');
    }

    [backgroundColor, textColor, borderClass, className].forEach((x) => {
      if (x) {
        fullClassName.push(x);
      }
    });

    if (width) {
      fullClassName.push(`sm:w-[${width}rem] w-[250px]`);
    } else {
      fullClassName.push('w-full');
    }

    if (height) {
      fullClassName.push(`h-[${height}rem]`);
    }
    
    return fullClassName.join(' ');
  }, [backgroundColor, borderClass, className, height, isDisabled, textColor, width]);

  return (
    <button
      disabled={isDisabled}
      className={getClassName()}
      onClick={onClick}
      type={type}
    >
      <span className="flex flex-row justify-center gap-3">
        {IconBefore && (
          <IconBefore className="max-w-5 text-white" />
        )}
        {children}
        {IconAfter && (
          <IconAfter className="max-w-5 text-white" />
        )}
      </span>
    </button>
  );
};

export default Button;

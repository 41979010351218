import { Link } from 'react-router-dom';

interface Props {
  links: { name: string; to: string; className?: string }[];
  children: React.ReactNode;
  className?: string;
}

const Breadcrumb = ({ links, children, className }: Props) => {
  return (
    <h1 className={`text-white mt-4 sm:text-start text-center ${className}`}>
      {links.map((l, i) => (
        <Link key={l.name} to={l.to} className="pointer text-gray-500">
          {i > 0 && ' / '} {l.name} {i === links.length - 1 && ' / '}
        </Link>
      ))}
      {children}
    </h1>
  );
};

export default Breadcrumb;

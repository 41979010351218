import React from "react";

interface DividerProps {
  mt?: number;
  mb?: number;
  width?: string | number;
  color?: string;
  mx?: string | number;
  px?: string | number;
}

const Divider: React.FC<DividerProps> = ({
  mt = 0,
  mb = 0,
  width = 0,
  color = "",
  mx = "auto",
  px = "0",
}) => {
  const defaultColor = "bg-dividerGray";

  return (
    <div
      className={`h-[1px] ${
        color || defaultColor
      } mt-${mt} mb-${mb} mx-${mx} px-${px}`}
      style={{
        maxWidth: width ? `${width}rem` : '100%',
        backgroundColor: `${color || defaultColor}`,
        width: '100%',
      }}
    ></div>
  );
};

export default Divider;

import { useCallback, useEffect, useMemo, useState } from "react";

export const useResponsiveWindow = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = useCallback(() => {
    setWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [handleWindowSizeChange]);

  const isMobile = useMemo(() => width <= 768, [width]);
  const isTablet = useMemo(() => width <= 1024, [width]);
  const isDesktop = useMemo(() => width > 1024, [width]);

  return { isMobile, isTablet, isDesktop };
};
/* eslint-disable jsx-a11y/anchor-has-content */
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";

import InputField from "../components/InputField";
import Button from "../components/buttons/Button";
import GoogleButton from "../components/buttons/link-buttons/google-btn";
import { useAuth } from "../hooks/useAuth";
import { ROUTE_LOGIN, ROUTE_MY_FIELDS } from "../router/constants";
import { register as registerRequest } from "../api/auth";
import DarkenBackground from "../components/DarkenBackground";
import { registerSchema } from "../validation/schemas/register";
import { useTranslation } from "react-i18next";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import SwitchLanguageButton from "../components/SwitchLanguageButton";

interface IFormInput {
  name: string;
  email: string;
  phone:  string;
  password: string;
  repeatPassword: string;
}

const RegisterPage = () => {
  const { isAuthenticated } = useAuth();

  const navigate = useNavigate();

  const { t } = useTranslation();

  const { register, handleSubmit, reset, setError, formState: { errors, isValid } } = useForm<IFormInput>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      password: '',
      repeatPassword: '',
    },
    resolver: yupResolver(registerSchema),
    mode: 'onBlur',
  });

  const handleRegister = async ({ repeatPassword, ...payload }: IFormInput) => {
    try {
      const { data, error } = await registerRequest(payload);

      if (error) {
        setError('email', { type: 'custom', message: error.error });
        return;
      }

      if (data?.success) {
        navigate(ROUTE_LOGIN);
      }

      reset({}, {
        keepErrors: true,
        keepDirty: true,
      });
    } catch (err) {
      console.log(err);
    }
  }

  if (isAuthenticated()) {
    return <Navigate to={ROUTE_MY_FIELDS} replace />
  }

  return (
    <div className="relative min-h-screen h-full flex justify-center overflow-auto">
      <DarkenBackground />

      <div className="z-20 justify-center w-[386px] flex flex-col items-center">
        <div className="flex flex-col justify-center items-center mb-8">
          <h3 className="text-3xl text-white font-montserrat font-light tracking-[-0.64px]">
            <b className="font-montserrat font-semibold">{t(COMMON_TRANSLATE_KEYS.CREATE)}</b> {t(COMMON_TRANSLATE_KEYS.ACCOUNT)}
          </h3>
        </div>
        <form
          onSubmit={handleSubmit(handleRegister)}
          className="flex flex-col justify-center mb-8 gap-4 w-full px-3"
          autoComplete="off"
        >
          <InputField
            {...register('name')}
            placeholder={t(COMMON_TRANSLATE_KEYS.NAME)}
            width={24}
            error={t(errors.name?.message || '')}
            autoComplete="off"
          />
          
          <InputField
            {...register('email')}
            placeholder={t(COMMON_TRANSLATE_KEYS.EMAIL)}
            width={24}
            error={t(errors.email?.message || '')}
            autoComplete="off"
          />

          <InputField
            {...register('phone')}
            placeholder={t(COMMON_TRANSLATE_KEYS.PHONE)}
            width={24}
            error={t(errors.phone?.message || '')}
            autoComplete="off"
          />

          <InputField
            {...register('password')}
            placeholder={t(COMMON_TRANSLATE_KEYS.PASSWORD)}
            type="password"
            width={24}
            error={t(errors.password?.message || '')}
            autoComplete="new-password"
          />

          <InputField
            {...register('repeatPassword')}
            placeholder={t(COMMON_TRANSLATE_KEYS.REPEAT_PASSWORD)}
            type="password"
            width={24}
            error={t(errors.repeatPassword?.message || '')}
            autoComplete="off"
          />

          <Button
            type="submit"
            backgroundColor="bg-mainGreen"
            textColor="text-black-300"
            isDisabled={!isValid}
          >
            {t(COMMON_TRANSLATE_KEYS.REGISTER_BUTTON)}
          </Button>

          <div className="justify-center pt-2 pb-4">
            <div className="flex items-center">
              <hr className="inline-flex flex-1" />
              <p className="text-white text-sm inline-flex px-3 my-0">{t(COMMON_TRANSLATE_KEYS.OR_LOGIN_WITH)}</p>
              <hr className="inline-flex flex-1" />
            </div>
          </div>

          <div className="flex row col-span-3 justify-center">
            <GoogleButton width={8} backgroundColor="bg-white" />
          </div>
        </form>

        <p className="text-white mb-3">{t(COMMON_TRANSLATE_KEYS.ALREADY_HAVE_ACC)}? <Link to={ROUTE_LOGIN} className="text-mainGreen">{t(COMMON_TRANSLATE_KEYS.LOGIN)}</Link></p>

        <SwitchLanguageButton />
      </div>
    </div>
  );
};

export default RegisterPage;

import { CSSProperties } from "react";
import { Switch } from "@headlessui/react";

interface SwitchButtonProps {
  children: string;
  width?: number;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  style?: CSSProperties;
}

const SwitchButton = ({
  children,
  width,
  isSelected,
  isDisabled,
  onClick,
  style,
}: SwitchButtonProps) => {
  return (
    <div className={isDisabled ? "cursor-not-allowed opacity-50 w-full" : "w-full"}>
      <Switch.Group>
        <div
          className={`${isDisabled ? "cursor-not-allowed opacity-50 " : ""} + 
            flex flex-row justify-between items-center px-2 bg-backGroundGray rounded-2xl h-[48px] w-full`}
          style={style}
        >
          <Switch.Label
            className={`${
              isDisabled ? "cursor-not-allowed opacity-50 " : ""
            } text-white  whitespace-wrap`}
          >
            {children}
          </Switch.Label>
          <Switch
            checked={isSelected}
            onChange={onClick}
            disabled={isDisabled}
            className={`${
              isSelected ? "bg-white" : "bg-dividerGray"
            } relative inline-flex h-[32px]  items-center rounded-full transition-colors min-w-14`}
          >
            <span
              className={`${
                isSelected
                  ? "translate-x-6 bg-functionalGreen"
                  : "translate-x-1 bg-backGroundGray"
              } 
              ${isDisabled ? "cursor-not-allowed opacity-50 " : ""}
              inline-block h-[24px] w-[24px] transform rounded-full transition-transform `}
            />
          </Switch>
        </div>
      </Switch.Group>
    </div>
  );
};

export default SwitchButton;

import React, { useCallback, useEffect, useState } from 'react';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import EventCalendar from '../components/calendar/event-calendar';
import ReservationDetailsModal from '../components/modals/ReservationDetailsModal';
import PlayerDetailsModal from '../components/modals/PlayerDetailsModal';
import { Player } from '../types/players';
import { Reservation } from '../types/reservations';
import { getReservationsByDatePeriod } from '../api/reservations';
import { fetchComplexById } from '../api/complexes';
import { Complex } from '../types';
import { ROUTE_NOT_FOUND } from '../router/constants';
import LoadingScreen from './LoadingScreen';
import Breadcrumb from '../components/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { COMMON_TRANSLATE_KEYS } from '../constants/translate-keys';

const ArenaCalendarPage = () => {
  const { id, fieldId } = useParams<{ id: string; fieldId: string }>();
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [complex, setComplex] = useState<Complex | null>(null);
  const [complexLoading, setComplexLoading] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSecondDrawerOpen, setSecondDrawerOpen] = useState(false);

  const [selectedReservation, setSelectedReservation] = useState<Reservation | null>(null);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);

  const [startDate, setStartDate] = useState(dayjs().startOf('week').toDate());
  const [endDate, setEndDate] = useState(dayjs(startDate).endOf('week').toDate());

  const location = useLocation();

  const { t } = useTranslation();

  const fetchReservations = useCallback(async () => {
    const { data, error } = await getReservationsByDatePeriod(
      Number(id),
      Number(fieldId),
      startDate,
      endDate,
    );

    if (error) {
      toast.error(error.error);
      return;
    }

    if (data) {
      setReservations(data);
    }
  }, [id, fieldId, startDate, endDate]);

  const fetchComplexDetails = useCallback(async () => {
    setComplexLoading(true);

    const { data, error } = await fetchComplexById(Number(id));

    setComplexLoading(false);

    if (error) {
      toast.error(error.error);
      return;
    }

    if (data) {
      setComplex(data);
    }
  }, [id]);

  useEffect(() => {
    Promise.all([fetchReservations(), fetchComplexDetails()])
  }, [fetchReservations, fetchComplexDetails]);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const openSecondDrawer = () => setSecondDrawerOpen(true);
  const closeSecondDrawer = () => setSecondDrawerOpen(false);

  const handleShowDetails = (res: Reservation) => {
    setSelectedReservation(res);
    openDrawer();
  };

  const handlePlayerClick = (player: Player) => {
    setSelectedPlayer(player);
    openSecondDrawer();
  }

  if (!complex) {
    return <LoadingScreen />;
  }

  if (!complex && !complexLoading) {
    return <Navigate to={ROUTE_NOT_FOUND} />
  }

  return (
    <>
      <div className="overflow-auto h-screen">
        <Breadcrumb
          links={[
            {
              name: t(COMMON_TRANSLATE_KEYS.COMPLEXES),
              to: '/my-fields',
              className: "pointer text-gray-500"
            },
            {
              name: `${complex.name} (${t(COMMON_TRANSLATE_KEYS.FIELDS)})`,
              to: `/arena/${id}/info`,
              className: "pointer text-gray-500",
            },
            {
              name: `${location.state?.fieldName}`,
              to: `/arena/${id}/field-reservation/${fieldId}`,
              className: "pointer text-gray-500",
            }
          ]}
          className="mx-5"
        >
          {t(COMMON_TRANSLATE_KEYS.RESERVATIONS)}
        </Breadcrumb>
        <EventCalendar
          complexName={location.state?.complexName}
          fieldName={location.state?.fieldName}
          reservations={reservations}
          complexId={id}
          fieldId={fieldId}
          onShowDetailsClick={handleShowDetails}
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          endDate={endDate}
          closingHours={complex.closingHours}
          openingHours={complex.openingHours}
        />
      </div>

      <div>
        <div className="relative z-30">
          <ReservationDetailsModal
            reservation={selectedReservation}
            closeDrawer={closeDrawer}
            isOpen={isDrawerOpen}
            onPlayerClick={handlePlayerClick}
            fetchReservations={fetchReservations}
          />
        </div>
      </div>

      {isSecondDrawerOpen && <PlayerDetailsModal close={closeSecondDrawer} player={selectedPlayer} team={selectedReservation?.team} />}
    </>
  );
};

export default ArenaCalendarPage;

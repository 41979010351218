import { useTranslation } from 'react-i18next';
import { useLogout } from '../../hooks/useLogout';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import {
  ROUTE_HELP,
  ROUTE_MY_FIELDS,
  ROUTE_SETTINGS,
} from '../../router/constants';
import { SidebarItems } from './sidebar-items';
import { Disclosure } from '@headlessui/react';
import Logo from '../Logo';
import SidebarMenuItem from './SidebarMenuItem';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Navbar from '../Navbar';

interface Props {
  defaultOpen?: boolean;
}

const CollapsedSidebar = ({ defaultOpen = false }: Props) => {
  const { t } = useTranslation();
  const { logout } = useLogout();

  const navigate = useNavigate();
  const location = useLocation();

  const getTabFromPath = (path: string) => {
    if (path.includes('/settings')) return 'Settings';
    if (path.includes('/my-fields')) return 'MyFields';
    if (path.includes('/reservation')) return 'Reservation';
    if (path.includes('/help')) return 'Help';
    if (path.includes('/logout')) return 'Log Out';
    return 'MyFields';
  };

  const [selectedTab, setSelectedTab] = useState(
    getTabFromPath(location.pathname)
  );

  useEffect(() => {
    setSelectedTab(getTabFromPath(location.pathname));
  }, [location.pathname]);

  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
    if (tabName === 'Settings') {
      navigate(ROUTE_SETTINGS);
    }
    if (tabName === 'MyFields') {
      navigate(ROUTE_MY_FIELDS);
    }
    if (tabName === SidebarItems.RESERVATION.name) {
      navigate(SidebarItems.RESERVATION.route);
    }
    if (tabName === 'Help') {
      navigate(ROUTE_HELP);
    }
    if (tabName === 'Log Out') {
      logout();
    }
  };

  return (
    <div className="lg:hidden flex w-full sm:justify-start px-8 justify-center py-3">
      <Disclosure
        as="div"
        className="flex flex-col w-full"
        defaultOpen={defaultOpen}
      >
        <Disclosure.Button className="flex justify-between w-full items-center">
          {({ open }) => (
            <>
              <Logo className="text-white" />
              <Bars3Icon
                color="white"
                width={35}
                height={35}
                className={`${open ? 'border-white' : 'border border-gray-400'} rounded-md`}
              />
            </>
          )}
        </Disclosure.Button>
        <Disclosure.Panel as="div">
          <div className="text-white p-5 gap-10 flex flex-row flex-wrap items-center justify-center">
            {Object.values(SidebarItems).map((item) => (
              <SidebarMenuItem
                key={item.name}
                name={t(item.label_key)}
                imgSrc={
                  selectedTab === item.name ? item.highlightedImage : item.img
                }
                onClick={() => handleTabClick(item.name)}
                isSelected={selectedTab === item.name}
              />
            ))}
          </div>

          <Navbar />
        </Disclosure.Panel>
      </Disclosure>
    </div>
  );
};

export default CollapsedSidebar;

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import Button from '../components/buttons/Button';
import ComplexCard from '../components/cards/ComplexCard';
import { useNavigate } from 'react-router-dom';
import { ROUTE_EDIT_ARENA_INFO } from '../router/constants';
import { getToken } from '../utils/token';
import FieldCard from '../components/cards/FieldCard';
import { useAuth } from '../hooks/useAuth';
import OwnerBar from '../components/OwnerBar';
import { COMMON_TRANSLATE_KEYS } from '../constants/translate-keys';
import { useTranslation } from 'react-i18next';
import Breadcrumb from '../components/Breadcrumb';

const initialPageLimit = 4;

const ArenaInfo = () => {
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const [complex, setComplex] = useState<any>(null);
  const [fields, setFields] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageLimit, setPageLimit] = useState(initialPageLimit);
  const [hasMoreFields, setHasMoreFields] = useState(false);
  const navigate = useNavigate();

  const { user } = useAuth();

  useEffect(() => {
    fetchComplex();
  }, [id]);

  const fetchComplex = async () => {
    if (!id) {
      return;
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${id}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      const complexData = response.data;
      setComplex(complexData);
      if (complexData) {
        fetchFields(complexData.id, 1, initialPageLimit);
      }
    } catch (error) {
      console.error('Error fetching complex:', error);
    }
  };

  const fetchFields = async (
    complexId: string,
    page: number,
    limit: number
  ) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${complexId}/fields?page=${page}&limit=${limit}`,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );
      setFields(response.data.fields);
      setTotalCount(response.data.totalCount);
      setHasMoreFields(response.data.hasNext);
    } catch (error) {
      console.error('Error fetching fields:', error);
    }
  };

  const loadMore = async () => {
    if (hasMoreFields) {
      const newPageLimit = pageLimit + initialPageLimit;
      setPageLimit(newPageLimit);
      await fetchFields(complex.id, 1, newPageLimit);
    }
  };

  if (!complex) {
    return <div className="text-mainWhite">Loading...</div>;
  }

  const renderFeature = (
    label: string,
    feature: boolean,
    t: (key: string) => string
  ) => (
    <div className="flex flex-row justify-between my-2">
      <p className="text-textGray">{t(label)}:</p>
      <p>
        {feature ? t(COMMON_TRANSLATE_KEYS.YES) : t(COMMON_TRANSLATE_KEYS.NO)}
      </p>
    </div>
  );

  return (
    <>
      <div className="mx-[30px]">
        <Breadcrumb
          className="sm:mb-8 mb-10"
          links={[
            {
              name: t(COMMON_TRANSLATE_KEYS.COMPLEXES),
              to: '/my-fields',
            },
          ]}
        >
          {complex.name} ({t(COMMON_TRANSLATE_KEYS.COMPLEX)})
        </Breadcrumb>
        <div>
          <ComplexCard
            title={complex.name}
            location={`${complex.city}, ${complex.country} | ${complex.address}`}
            width="xl"
            complex={complex}
          >
            <div className="flex md:flex-row flex-col gap-4 items-center flex-wrap justify-end">
              <Button
                backgroundColor="bg-functionalGreen"
                width={12}
                textColor="text-gray-800"
                onClick={() => navigate(`/arena/add-field/${complex.id}`)}
              >
                {t(COMMON_TRANSLATE_KEYS.ADD_FIELD)}
              </Button>
              <Button
                border="border-mainBlue"
                backgroundColor="bg-gray-800"
                width={12}
                textColor="text-mainBlue"
                onClick={() =>
                  navigate(`${ROUTE_EDIT_ARENA_INFO}/${complex.id}`)
                }
              >
                {t(COMMON_TRANSLATE_KEYS.EDIT_INFO)}
              </Button>
            </div>
          </ComplexCard>
        </div>
        <div className="flex lg:flex-row flex-col gap-8 mt-6 text-mainWhite">
          <div className="w-full">
            {/* <OwnerBar ownerText={t(COMMON_TRANSLATE_KEYS.COMPLEX_OWNER)} /> */}
            {totalCount > 0 ? (
              <>
                <div className="my-4">
                  {`${t(COMMON_TRANSLATE_KEYS.ALL_FIELDS)} (${totalCount})`}
                </div>
                <div className="flex flex-wrap gap-4 my-4">
                  {fields.map((field: any) => (
                    <FieldCard
                      key={field.id}
                      title={field.name}
                      location={[
                        field.district,
                        `${field.city} (${complex.fieldsCount})`,
                      ]
                        .filter((x) => x)
                        .join(', ')}
                      width="lg"
                      field={field}
                      complex={complex}
                    >
                      <Button
                        className="mx-auto"
                        border="border-mainBlue"
                        backgroundColor="bg-gray-800"
                        width={23}
                        textColor="text-mainBlue"
                        onClick={() =>
                          navigate(
                            `/arena/${complex.id}/field-reservation/${field.id}`
                          )
                        }
                      >
                        {t(COMMON_TRANSLATE_KEYS.RESERVATIONS)}
                      </Button>
                    </FieldCard>
                  ))}
                </div>

                {hasMoreFields && (
                  <Button onClick={loadMore}>
                    {t(COMMON_TRANSLATE_KEYS.LOAD_MORE)}
                  </Button>
                )}
              </>
            ) : (
              <div className="flex flex-col items-center justify-center w-full my-16 md:my-10">
                <p className="flex w-fit text-center">{t(COMMON_TRANSLATE_KEYS.NO_FIELDS)}</p>
              </div>
            )}
          </div>
          {/* <div className="lg:w-1/3 text-mainWhite mb-12">
            <div>
              <h2 className="text-mainWhite">
                {t(COMMON_TRANSLATE_KEYS.CONTACT_OWNER)}
              </h2>
              <div className="flex flex-row justify-between my-2">
                <p className="text-textGray">
                  {t(COMMON_TRANSLATE_KEYS.EMAIL)} :
                </p>
                <p>{user?.email || 'N/A'}</p>
              </div>
              <div className="flex flex-row justify-between my-2">
                <p className="text-textGray">
                  {t(COMMON_TRANSLATE_KEYS.PHONE)}:
                </p>
                <p>{complex.phone}</p>
              </div>
            </div>
            <div className="mt-4">
              <h2 className="text-mainWhite">
                {t(COMMON_TRANSLATE_KEYS.COMPLEX_INFO)}
              </h2>
              <div className="mt-4">
                {renderFeature(
                  COMMON_TRANSLATE_KEYS.PARKING,
                  complex.parking,
                  t
                )}
                {renderFeature(
                  COMMON_TRANSLATE_KEYS.DRESSING_ROOM,
                  complex.dressing,
                  t
                )}
                {renderFeature(
                  COMMON_TRANSLATE_KEYS.COFFEE_SHOP,
                  complex.coffee,
                  t
                )}
                {renderFeature(
                  COMMON_TRANSLATE_KEYS.PROVIDE_BALLS,
                  complex.providesBalls,
                  t
                )}
                {renderFeature(
                  COMMON_TRANSLATE_KEYS.PROVIDE_T_SHIRTS,
                  complex.providesTShirts,
                  t
                )}
              </div>
            </div>
            {complex?.description && (
              <div className="mt-4">
                <h2 className="text-mainWhite">
                  {t(COMMON_TRANSLATE_KEYS.DESCRIPTION)}
                </h2>
                <p className="mt-4 text-textGray whitespace-pre-wrap">
                  {complex.description}
                </p>
              </div>
            )}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ArenaInfo;

import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import EventCalendar from '../components/calendar/event-calendar';
import ReservationDetailsModal from '../components/modals/ReservationDetailsModal';
import PlayerDetailsModal from '../components/modals/PlayerDetailsModal';
import { Player } from '../types/players';
import { Reservation } from '../types/reservations';
import { getReservationsByDatePeriod } from '../api/reservations';
import { fetchComplexById } from '../api/complexes';
import { Complex } from '../types';
import LoadingScreen from '../pages/LoadingScreen';
import { COMMON_TRANSLATE_KEYS } from '../constants/translate-keys';

type Props = {
  id?: string;
  fieldId?: string;
  reservations: Reservation[];
  setReservations: (reservations: Reservation[]) => void;
  initialStartDate: Date | null;
};

const ArenaCalendar = ({
  id,
  fieldId,
  reservations,
  setReservations,
  initialStartDate,
}: Props) => {
  const [complex, setComplex] = useState<Complex | null>(null);
  const [complexLoading, setComplexLoading] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSecondDrawerOpen, setSecondDrawerOpen] = useState(false);

  const [selectedReservation, setSelectedReservation] =
    useState<Reservation | null>(null);
  const [selectedPlayer, setSelectedPlayer] = useState<Player | null>(null);

  const [startDate, setStartDate] = useState(
    initialStartDate
      ? dayjs(initialStartDate).startOf('week').toDate()
      : dayjs().startOf('week').toDate()
  );
  const [endDate, setEndDate] = useState(
    initialStartDate
      ? dayjs(initialStartDate).endOf('week').toDate()
      : dayjs(startDate).endOf('week').toDate()
  );

  const location = useLocation();

  const { t } = useTranslation();

  const fetchReservations = useCallback(async () => {
    const { data, error } = await getReservationsByDatePeriod(
      Number(id),
      Number(fieldId),
      startDate,
      endDate
    );

    if (error) {
      toast.error(error.error);
      return;
    }

    if (data) {
      setReservations(data);
    }
  }, [id, fieldId, startDate, endDate, setReservations]);

  const fetchComplexDetails = useCallback(async () => {
    setComplexLoading(true);

    const { data, error } = await fetchComplexById(Number(id));

    setComplexLoading(false);

    if (error) {
      toast.error(error.error);
      return;
    }

    if (data) {
      setComplex(data);
    }
  }, [id]);

  useEffect(() => {
    fetchReservations();
  }, [fetchReservations]);

  useEffect(() => {
    fetchComplexDetails();
  }, [fetchComplexDetails]);

  const openDrawer = () => setIsDrawerOpen(true);
  const closeDrawer = () => setIsDrawerOpen(false);

  const openSecondDrawer = () => setSecondDrawerOpen(true);
  const closeSecondDrawer = () => setSecondDrawerOpen(false);

  const handleShowDetails = (res: Reservation) => {
    setSelectedReservation(res);
    openDrawer();
  };

  const handlePlayerClick = (player: Player) => {
    setSelectedPlayer(player);
    openSecondDrawer();
  };

  useEffect(() => {
    if (initialStartDate) {
      setStartDate(dayjs(initialStartDate).startOf('week').toDate());
      setEndDate(dayjs(initialStartDate).endOf('week').toDate());
    }
  }, [initialStartDate]);

  if (complexLoading) {
    return <LoadingScreen />;
  }

  if (!complex) {
    return <h1>{t(COMMON_TRANSLATE_KEYS.UNEXPECTED_ERROR)}</h1>;
  }

  return (
    <>
      <div className="overflow-auto h-screen">
        <EventCalendar
          complexName={location.state?.complexName}
          fieldName={location.state?.fieldName}
          reservations={reservations}
          complexId={id}
          fieldId={fieldId}
          onShowDetailsClick={handleShowDetails}
          startDate={startDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          endDate={endDate}
          closingHours={complex.closingHours}
          openingHours={complex.openingHours}
        />
      </div>

      <div>
        <div className="relative z-30">
          <ReservationDetailsModal
            reservation={selectedReservation}
            closeDrawer={closeDrawer}
            isOpen={isDrawerOpen}
            onPlayerClick={handlePlayerClick}
            fetchReservations={fetchReservations}
          />
        </div>
      </div>

      {isSecondDrawerOpen && (
        <PlayerDetailsModal
          close={closeSecondDrawer}
          player={selectedPlayer}
          team={selectedReservation?.team}
        />
      )}
    </>
  );
};

export default ArenaCalendar;

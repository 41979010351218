import React, { ReactNode, useMemo } from "react";

import { useNavigate } from "react-router-dom";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { Complex } from "../../types";
import { useTranslation } from "react-i18next";
import { COMMON_TRANSLATE_KEYS } from "../../constants/translate-keys";
import ImageCarousel from "../carousel/ImageCarousel";
import { getFileNameFromUrl } from "../../utils/files";
import { useAuth } from "../../hooks/useAuth";

interface ComplexCardProps {
  title: string;
  location: string;
  children: ReactNode;
  complex: Complex;
  width?: "auto" | "sm" | "md" | "lg" | "xl";
}

const ComplexCard: React.FC<ComplexCardProps> = ({
  title,
  location,
  children,
  width = "",
  complex,
}) => {
  const { user } = useAuth();

  const getWidthClass = (width: string) => {
    switch (width) {
      case "sm":
        return "";
      case "md":
        return "";
      case "lg":
        return "lg:w-[49%] w-full";
      case "xl":
        return "w-full";
      default:
        return "";
    }
  };

  const navigate = useNavigate();

  const { t } = useTranslation();

  const carouselImages = useMemo(() => {
    return complex?.imageUrls.map((src) => ({
      id: getFileNameFromUrl(src, 'complex'),
      src: src,
    })) || [];
  }, [complex]);

  const renderButton = width !== "xl" && (
    <button
      className="bg-secondaryBlack text-mainWhite mb-5 sm:mb-0"
      onClick={() =>
        navigate(`/arena/${complex?.id}/fields?name=${complex?.name}`)
      }
    >
      <span className="flex flex-row justify-between">
        {t(COMMON_TRANSLATE_KEYS.ALL_FIELDS)} <ChevronRightIcon height={24} width={24} />
      </span>
    </button>
  );

  return (
    <div
      className={`rounded-lg bg-secondaryBlack shadow-lg ${getWidthClass(
        width
      )}`}
    >
      {width === "xl" ? (
        <>
          <div
            className={`relative rounded-lg bg-secondaryBlack shadow-lg ${getWidthClass(
              width
            )}`}
          >
            {carouselImages.length > 0 && (
              <div className="relative flex flex-col">
                <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-secondaryBlack via-semi-transparent to-transparent z-10" />
                <ImageCarousel
                  images={carouselImages}
                  containerClassName="sm:relative absolute"
                  itemClassName="w-full h-[300px] p-3"
                  itemsShown={{
                    desktop: 3,
                  }}
                  showDots={false}
                  readonly
                />
              </div>
            )}
            <div className="flex flex-col h-full sm:items-start items-center" style={{ paddingTop: carouselImages.length === 0 ? '300px' : 0 }}>
              <h2 className="text-6xl text-mainWhite sm:text-start text-center font-bold sm:pl-4">
                {`${t(COMMON_TRANSLATE_KEYS.COMPLEX)} ${title}`}
              </h2>
              <div className="p-4 text-mainWhite flex sm:flex-row flex-col sm:items-end justify-between gap-5 w-full">
                <span className="text-base sm:text-start text-center sm:max-w-[700px] flex flex-col gap-1">
                  <p>{`${t(COMMON_TRANSLATE_KEYS.COUNTRY)}: ${complex.country}`}</p>
                  <p>{`${t(COMMON_TRANSLATE_KEYS.CITY)}: ${complex.city}`}</p>
                  <p>{`${t(COMMON_TRANSLATE_KEYS.ADDRESS)}: ${complex.address}`}</p>
                  {/* <p>{location}</p> */}
                  {complex?.district && <p>{`${t(COMMON_TRANSLATE_KEYS.DISTRICT)}: ${complex?.district}`}</p>}
                  <hr className="border-0 my-2"></hr>
                  <p>{`${t(COMMON_TRANSLATE_KEYS.OWNER)}: ${user?.name}`}</p>
                  <p>{`${t(COMMON_TRANSLATE_KEYS.PHONE)}: ${user?.phone}`}</p>
                </span>
                {children}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          {carouselImages.length > 0 && (
            <div className="relative overflow-hidden rounded-t-lg">
              <ImageCarousel
                images={carouselImages}
                containerClassName="absolute"
                itemClassName="h-[200px]"
                itemsShown={{
                  smallDesktop: 1,
                  desktop: 2,
                  superLargeDesktop: 3,
                }}
                showDots={false}
                readonly
              />
              <div className="absolute bottom-0 left-0 w-full h-24 bg-gradient-to-t from-secondaryBlack via-semi-transparent to-transparent z-10" />
            </div>
          )}
          <div className="p-4 text-mainWhite" style={{ paddingTop: carouselImages.length === 0 ? '200px' : 0 }}>
            <div className="flex sm:flex-row flex-col-reverse justify-between mb-3">
              <div>
                <h2 className="text-xl font-bold mb-2">{title}</h2>
                <p className="text-sm mb-2">{location}</p>
              </div>
              <div>{renderButton}</div>
            </div>
            {children}
          </div>
        </>
      )}
    </div>
  );
};

export default ComplexCard;

import { Dialog } from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';

import Button from '../buttons/Button';
import { acceptReservation, declineReservation } from '../../api/reservations';
import InputField from '../InputField';
import { Reservation } from '../../types/reservations';
import { Field } from '../../types';
import StatusBadge from '../StatusBadge';
import { FieldInReservation } from '../../types/fields';
import { getRecurringDays } from '../../utils/dates';
import { useTranslation } from 'react-i18next';
import { COMMON_TRANSLATE_KEYS } from '../../constants/translate-keys';

import LocationIcon from '../../icons/svg/location.svg';
import PlayerIcon from '../../icons/svg/player.svg';
import ClockIcon from '../../icons/svg/clock.svg';

import 'dayjs/locale/bg';
import { useResponsiveWindow } from '../../hooks/useResponsiveWindow';

interface ReservationCardProps {
  reservation: Reservation;
  field: Field | FieldInReservation;
  fetchReservations: () => Promise<void>;
  width?: number;
  height?: number;
  onClick?: () => void;
}

interface IFormInput {
  reason: string;
}

const ReservationCard: React.FC<ReservationCardProps> = ({
  reservation,
  width,
  height,
  fetchReservations,
  field,
  onClick,
}) => {
  const [isOpen, setOpen] = useState(false);

  const { register, getValues } = useForm<IFormInput>({
    defaultValues: {
      reason: '',
    },
  });

  const { t, i18n } = useTranslation();

  const {
    status,
    date,
    start,
    end,
    team,
    price,
    id,
    isRecurring,
    recurringDays,
    recurringEndDate,
    recurringRepeatCount,
  } = reservation;

  const { isMobile } = useResponsiveWindow();

  const openModal = () => setOpen(true);
  const closeModal = () => setOpen(false);

  const isPending = useMemo(() => status === 'PENDING', [status]);
  const formattedDate = useMemo(
    () => (date ? dayjs(date).locale(i18n.language).format('LLLL') : '-'),
    [date, i18n.language]
  );
  const formattedRecurringDate = useMemo(
    () =>
      recurringEndDate
        ? dayjs(recurringEndDate).locale(i18n.language).format('LLLL')
        : '-',
    [recurringEndDate, i18n.language]
  );

  const handleAcceptReservation = useCallback(async () => {
    const { data, error } = await acceptReservation(id);

    if (error) {
      const { error: errMsg } = error;

      toast(errMsg, {
        toastId: 'accept-res-error',
        theme: 'dark',
        bodyClassName: 'text-center',
      });

      return;
    }

    if (data) {
      await fetchReservations();
    }
  }, [fetchReservations, id]);

  const handleDeclineReservation = useCallback(async () => {
    const payload = {
      reason: getValues('reason'),
    };

    const { data, error } = await declineReservation(id, payload);

    if (error) {
      const { error: errMsg } = error;

      toast(errMsg, {
        toastId: 'accept-res-error',
        theme: 'dark',
        bodyClassName: 'text-center',
      });

      return;
    }

    if (data) {
      await fetchReservations();
    }

    closeModal();
  }, [fetchReservations, id, getValues]);

  const renderReservationDetailsInModal = () => {
    const { team, date, start, end } = reservation;
    const { address, name } = field;

    return (
      <>
        <div className="flex flex-row items-start gap-2">
          <img src={LocationIcon} alt="Location" className="mt-1" />
          <p className="text-white">{`${address} (${name})`}</p>
        </div>
        {team?.name && (
          <div className="flex flex-row items-center gap-2">
            <img src={PlayerIcon} alt="Team" />
            <p className="text-white">{`${team.name}`}</p>
          </div>
        )}
        <div className="flex flex-row items-center gap-2">
          <img src={ClockIcon} alt="Clock" />
          <p className="text-white">{`${
            date ? formattedDate : t(COMMON_TRANSLATE_KEYS.RECURRING)
          } (${start} - ${end})`}</p>
        </div>
      </>
    );
  };

  const cardStyle = {
    width: width ? `${width}rem` : '100%',
    height: height ? `${height}rem` : undefined,
  };

  return (
    <div
      className={`flex flex-col rounded-xl bg-additionalBlack `}
      style={cardStyle}
    >
      <div className="flex flex-col flex-grow p-4">
        <div className="flex sm:justify-between items-center sm:flex-row flex-col sm:mb-6">
          <div className="flex gap-x-3 justify-between sm:w-fit w-full flex-wrap">
            <div className="flex flex-col whitespace-nowrap">
              <div className="text-textGray">
                {t(COMMON_TRANSLATE_KEYS.DATE)}:
              </div>
              <div className="text-mainBlue">{formattedDate}</div>
            </div>
            <div className="flex flex-col whitespace-nowrap">
              <div className="text-textGray">
                {t(COMMON_TRANSLATE_KEYS.CHECK_IN)}:
              </div>
              <div>{start}</div>
            </div>
            <div className="flex flex-col whitespace-nowrap">
              <div className="text-textGray">
                {t(COMMON_TRANSLATE_KEYS.CHECK_OUT)}:
              </div>
              <div>{end}</div>
            </div>
          </div>
          <div className="flex items-center">
            <StatusBadge status={status} />
            {onClick && !isMobile ? (
              <EllipsisVerticalIcon
                height={30}
                onClick={onClick}
                className="cursor-pointer"
              />
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="flex sm:flex-row flex-col justify-between mb-6">
          <div className="flex flex-col">
            {team ? (
              <p>
                <span className="text-textGray">
                  {t(COMMON_TRANSLATE_KEYS.TEAM)}:{' '}
                </span>
                {team.name}
              </p>
            ) : (
              <span className="text-textGray">
                {t(COMMON_TRANSLATE_KEYS.TEAM)}: -{' '}
              </span>
            )}
            <p>
              <span className="text-textGray">
                {t(COMMON_TRANSLATE_KEYS.GAME_TYPE)}:{' '}
              </span>
              {reservation.isGamePublic
                ? ` ${t(COMMON_TRANSLATE_KEYS.PUBLIC_GAME_CONTEXT)}`
                : ` ${t(COMMON_TRANSLATE_KEYS.PRIVATE_GAME_CONTEXT)}`}
            </p>
            {isRecurring && recurringDays && (
              <p>
                <span className="text-textGray">
                  {t(COMMON_TRANSLATE_KEYS.RECURRING_DAYS)}:{' '}
                </span>
                {getRecurringDays(recurringDays).join(', ')}
              </p>
            )}
            {isRecurring && recurringEndDate && (
              <p>
                <span className="text-textGray">
                  {t(COMMON_TRANSLATE_KEYS.END_RECURRING_DAY)}:{' '}
                </span>
                {formattedRecurringDate}
              </p>
            )}
            {isRecurring && recurringRepeatCount && (
              <p>
                <span className="text-textGray">
                  {t(COMMON_TRANSLATE_KEYS.REPEAT)}:{' '}
                </span>
                {t(COMMON_TRANSLATE_KEYS.WEEK_COUNT, {
                  count: recurringRepeatCount,
                })}
              </p>
            )}
          </div>
          <div className="flex sm:flex-col gap-1 border-t sm:border-t-0 border-textGray mt-4 sm:mt-0 pt-1 sm:pt-0">
            <div> {t(COMMON_TRANSLATE_KEYS.FIELD_PAYMENT)}:</div>
            <div className="flex justify-end">
              {field.currency} {price.toFixed(2)}
            </div>
          </div>
        </div>

        {isPending && (
          <div className="flex flex-row justify-around gap-x-2">
            <Button
              backgroundColor="bg-functionalRed"
              textColor="text-mainWhite"
              width={isMobile ? undefined : 30}
              onClick={() => openModal()}
            >
              {t(COMMON_TRANSLATE_KEYS.DECLINE)}
            </Button>
            <Button
              backgroundColor="bg-mainGreen"
              textColor="text-mainBlack"
              width={isMobile ? undefined : 30}
              onClick={async () => await handleAcceptReservation()}
            >
              {t(COMMON_TRANSLATE_KEYS.ACCEPT)}
            </Button>
          </div>
        )}

        {isMobile && onClick ? (
          <div className="flex w-full mt-5">
            <Button backgroundColor="bg-mainBlue" textColor="text-mainWhite" onClick={onClick}>
              {t(COMMON_TRANSLATE_KEYS.SEE_DETAILS)}
            </Button>
          </div>
        ) : (
          <></>
        )}

        <Dialog
          open={isOpen}
          onClose={closeModal}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <Dialog.Overlay className="fixed inset-0 bg-black  opacity-50" />
          <div className="bg-additionalBlack p-6 rounded-xl absolute top-212 left-468 lg:max-w-[40%] mx-6">
            <div className="flex flex-row justify-between mb-3">
              <Dialog.Title className="text-white font-bold text-2xl">
                {t(COMMON_TRANSLATE_KEYS.DECLINE_RESERVATION_MODAL_TEXT)}
              </Dialog.Title>
              <button className="ps-3 pb-3 text-white" onClick={closeModal}>
                X
              </button>
            </div>
            <div className="w-full">
              {renderReservationDetailsInModal()}
              <p className="text-white text-sm mt-6 mb-4">
                {t(COMMON_TRANSLATE_KEYS.DECLINE_REASON)} (
                {t(COMMON_TRANSLATE_KEYS.OPTIONAL)})
              </p>
            </div>
            <div className="mb-6">
              <InputField
                {...register('reason')}
                placeholder={'Enter text'}
                height={3}
              />
            </div>
            <div className="flex justify-center">
              <Button
                textColor="text-white"
                backgroundColor="bg-functionalRed"
                width={16}
                height={3}
                onClick={async () => await handleDeclineReservation()}
              >
                {t(COMMON_TRANSLATE_KEYS.DECLINE)}
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ReservationCard;

import { COMMON_TRANSLATE_KEYS } from "../../constants/translate-keys";
import { ROUTE_RESERVATION } from "../../router/constants";

export const SidebarItems = {
  RESERVATION: {
    name: 'Reservation',
    img: '/icons/calendar-minus-highlighted.svg',
    highlightedImage: '/icons/calendar-minus-highlighted.svg',
    label_key: COMMON_TRANSLATE_KEYS.RESERVATION,
    route: ROUTE_RESERVATION,
  },
  MY_FIELDS: {
    name: 'MyFields',
    img: '/icons/basketball.svg',
    highlightedImage: '/icons/basketball-highlighted.svg',
    label_key: COMMON_TRANSLATE_KEYS.MY_FIELDS,
    route: '/my-fields',
  },
  SETTINGS: {
    name: 'Settings',
    img: '/icons/settings.svg',
    highlightedImage: '/icons/settings-highlighted.svg',
    label_key: COMMON_TRANSLATE_KEYS.SETTINGS,
    route: '/settings',
  },
  HELP: {
    name: 'Help',
    img: '/icons/help.svg',
    highlightedImage: '/icons/help-highlighted.svg',
    label_key: COMMON_TRANSLATE_KEYS.HELP,
    route: '/help',
  },
};
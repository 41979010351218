import React, { useState, useEffect, useCallback } from "react";
import Dropzone from "../components/Dropzone";
import InputField from "../components/InputField";
import SwitchButton from "../components/SwitchButton";
import Divider from "../components/Divider";
import Button from "../components/buttons/Button";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { getToken } from "../utils/token";
import { getFileNameFromUrl, toBase64 } from "../utils/files";
import DescriptionInput from "../components/DescriptionInput";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { COMMON_TRANSLATE_KEYS } from "../constants/translate-keys";
import { TImage } from "../types";
import { useResponsiveWindow } from "../hooks/useResponsiveWindow";

const formatTime = (value: string) => {
  const trimmed = value.replace(/[^0-9]/g, "");
  if (trimmed.length === 0) return "";
  if (trimmed.length <= 2) {
    return `${trimmed.padStart(2, "0")}:00`;
  }
  if (trimmed.length === 3) {
    return `${trimmed.slice(0, 2)}:${trimmed.slice(2).padEnd(2, "0")}`;
  }
  if (trimmed.length === 4) {
    return `${trimmed.slice(0, 2)}:${trimmed.slice(2)}`;
  }
  return value;
};

const isTimeInRange = (time: string) => {
  const [hours] = time.split(":").map(Number);
  return hours >= 1 && hours <= 23;
};

const EditArenaInfo = () => {
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [district, setDistrict] = useState("");
  const [phone, setPhone] = useState("");
  const [openingHours, setOpeningHours] = useState("");
  const [closingHours, setClosingHours] = useState("");
  const [description, setDescription] = useState("");
  const [parking, setParking] = useState(false);
  const [dressing, setDressing] = useState(false);
  const [showers, setShowers] = useState(false);
  const [coffee, setCoffee] = useState(false);
  const [providesBalls, setProvidesBalls] = useState(false);
  const [providesTShirts, setProvidesTShirts] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [phoneError, setPhoneError] = useState<string | null>(null);
  const [hoursError, setHoursError] = useState<string | null>(null);
  const [images, setImages] = useState<TImage[]>([]);

  // Images to remove on the server. Stores file names
  const [imagesToRemove, setImagesToRemove] = useState<string[]>([]);

  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const { isMobile } = useResponsiveWindow();

  useEffect(() => {
    const fetchComplexData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${id}`,
          {
            headers: { Authorization: `Bearer ${getToken()}` },
          }
        );
        const data = response.data;
        setName(data.name);
        setCountry(data.country);
        setCity(data.city);
        setAddress(data.address);
        setDistrict(data.district || "");
        setPhone(data.phone);
        setOpeningHours(data.openingHours);
        setClosingHours(data.closingHours);
        setDescription(data.description);
        setParking(data.parking);
        setDressing(data.dressing);
        setShowers(data.showers);
        setCoffee(data.coffee);
        setProvidesBalls(data.providesBalls);
        setProvidesTShirts(data.providesTShirts);
        setLatitude(data.latitude);
        setLongitude(data.longitude);
        
        if (data.imageUrls) {
          const currentImages = data.imageUrls.map((src: string) => {
            const id = getFileNameFromUrl(src, 'complex');

            return {
              id,
              src,
            };
          });

          setImages(currentImages);
        }
      } catch (error) {
        console.error("Error fetching complex data", error);
      }
    };

    fetchComplexData();
  }, [id]);

  const addImages = useCallback((newImages: TImage[]) => {
    setImages((prev) => [...prev, ...newImages]);
  }, []);

  const removeImage = useCallback((image: TImage) => {
    setImages((prev) => prev.filter((img) => img.id !== image.id));

    // If there is no file, that means that the image is uploaded on the server
    if (!image.file) {
      setImagesToRemove((prev) => [...prev, image.id]);
    }
  }, []);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const regex = /^[0-9]*$/;

    if (regex.test(value)) {
      setPhone(value);
      setPhoneError(null);
    } else {
      setPhoneError("Phone number can only contain numbers (0-9).");
    }
  };

  const handleOpeningHoursBlur = () => {
    const formattedTime = formatTime(openingHours);
    setOpeningHours(formattedTime);
    const error = validateHours(formattedTime, closingHours);
    setHoursError(error);
  };

  const handleClosingHoursBlur = () => {
    const formattedTime = formatTime(closingHours);
    setClosingHours(formattedTime);
    const error = validateHours(openingHours, formattedTime);
    setHoursError(error);
  };

  // const handleImageRemoval = (imageUrl: string) => {
  //   setImagesToRemove((prev) => [...prev, imageUrl]);
  //   setExistingImages((prev) => prev.filter((img) => img !== imageUrl));
  // };

  const validateHours = (opening: string, closing: string) => {
    let error: string | null = null;

    if (!/^\d{2}:\d{2}$/.test(opening)) {
      error = t(COMMON_TRANSLATE_KEYS.HOURS_ERROR);
    } else if (!/^\d{2}:\d{2}$/.test(closing)) {
      error = t(COMMON_TRANSLATE_KEYS.HOURS_ERROR);
    } else if (!isTimeInRange(opening)) {
      error = t(COMMON_TRANSLATE_KEYS.OPENING_HOURS_ERROR);
    } else if (!isTimeInRange(closing)) {
      error = t(COMMON_TRANSLATE_KEYS.CLOSING_HOURS_ERROR);
    } else if (opening >= closing) {
      error = t(COMMON_TRANSLATE_KEYS.OPEN_CLOSE_HOURS_ERROR);
    }
    return error;
  };
  const handleSubmit = async () => {
    if (phoneError) {
      toast.error(`${t(COMMON_TRANSLATE_KEYS.PHONE_ERROR)}`);
      return;
    }
    if (hoursError) {
      toast.error(`${t(COMMON_TRANSLATE_KEYS.HOURS_ERROR)}`);
      return;
    }

    const base64Images = await Promise.all(images.map((img => toBase64(img.file))))
      .then(res => res.filter(Boolean));

    const complexData = {
      name,
      country,
      city,
      address,
      district,
      phone,
      openingHours,
      closingHours,
      description,
      parking,
      dressing,
      showers,
      coffee,
      providesBalls,
      providesTShirts,
      latitude,
      longitude,
      images: base64Images,
      imagesToRemove,
    };

    try {
      await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/owner/complexes/${id}`,
        complexData,
        {
          headers: { Authorization: `Bearer ${getToken()}` },
        }
      );

      toast.success("Complex updated successfully!", {
        theme: "dark",
        className: "text-center",
        bodyClassName: "custom-toast-body",
      });

      navigate(`/arena/${id}/info`);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const errorResponse = error.response?.data;
        let errorMessage = "An unexpected error occurred";

        if (errorResponse && typeof errorResponse === "object") {
          const errorDetail = errorResponse.error || "An error occurred";
          errorMessage = errorDetail;
        } else if (errorResponse && typeof errorResponse === "string") {
          errorMessage = errorResponse;
        }

        toast.error(`Error updating complex: ${errorMessage}`, {
          theme: "dark",
          className: "text-center",
          bodyClassName: "custom-toast-body",
        });
      } else {
        toast.error("An unexpected error occurred", {
          theme: "dark",
          className: "text-center",
          bodyClassName: "custom-toast-body",
        });
      }

      console.error("Error updating complex", error);
    }
  };

  const resetForm = () => {
    setName("");
    setCountry("");
    setCity("");
    setAddress("");
    setDistrict("");
    setPhone("");
    setOpeningHours("");
    setClosingHours("");
    setDescription("");
    setParking(false);
    setDressing(false);
    setShowers(false);
    setCoffee(false);
    setProvidesBalls(false);
    setProvidesTShirts(false);
    setLatitude(0);
    setLongitude(0);
    setImages([]);
    setImagesToRemove([]);
  };

  const handleCancel = () => {
    resetForm();
    navigate(`/arena/${id}/info`);
  };

  return (
    <div className="sm:block mx-auto sm:w-auto flex flex-col my-4 px-4 w-full items-center">
      <div className="text-mainWhite flex flex-col w-full">
        <p>{t(COMMON_TRANSLATE_KEYS.PHOTOS_OF_THE_COMPLEX)}</p>
        <Dropzone addImages={addImages} removeImage={removeImage} images={images}  />
      </div>
      <div className="mt-4 w-full">
        <p className="text-mainWhite">
          {t(COMMON_TRANSLATE_KEYS.NAME)}{" "}
          <span className="text-functionalRed">*</span>
        </p>
        <InputField
          placeholder={t(COMMON_TRANSLATE_KEYS.NAME)}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="sm:flex-row flex-col flex gap-x-4 w-full">
        <div className="mt-4 w-full">
          <p className="text-mainWhite">
            {t(COMMON_TRANSLATE_KEYS.COUNTRY)}{" "}
            <span className="text-functionalRed">*</span>
          </p>
          <InputField
            placeholder={t(COMMON_TRANSLATE_KEYS.COUNTRY)}
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </div>
        <div className="mt-4 w-full">
          <p className="text-mainWhite">
            {t(COMMON_TRANSLATE_KEYS.REGION)}{" "}
            <span className="text-functionalRed">*</span>
          </p>
          <InputField
            placeholder={t(COMMON_TRANSLATE_KEYS.REGION)}
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </div>
      </div>
      <div className="mt-4 w-full">
        <p className="text-mainWhite">
          {t(COMMON_TRANSLATE_KEYS.ADDRESS)}{" "}
          <span className="text-functionalRed">*</span>
        </p>
        <InputField
          placeholder={t(COMMON_TRANSLATE_KEYS.REGION)}
          value={address}
          onChange={(e) => setAddress(e.target.value)}
        />
      </div>
      <div className="mt-4 w-full">
        <p className="text-mainWhite">{t(COMMON_TRANSLATE_KEYS.DISTRICT)}</p>
        <InputField
          placeholder={t(COMMON_TRANSLATE_KEYS.DISTRICT)}
          value={district}
          onChange={(e) => setDistrict(e.target.value)}
        />
      </div>
      <div className="mt-4 w-full">
        <p className="text-mainWhite">
          {t(COMMON_TRANSLATE_KEYS.CONTACT_PHONE)}
          <span className="text-functionalRed">*</span>
        </p>
        <InputField
          placeholder={t(COMMON_TRANSLATE_KEYS.CONTACT_PHONE)}
          value={phone}
          onChange={handlePhoneChange}
        />
        {phoneError && (
          <div className="text-functionalRed mb-2">{phoneError}</div>
        )}
      </div>
      <div className="mt-4 flex flex-row w-full gap-2">
        <div className="w-1/2">
          <p className="text-mainWhite">
            {t(COMMON_TRANSLATE_KEYS.OPENING_HOURS)}{" "}
            <span className="text-functionalRed">*</span>
          </p>
          <InputField
            placeholder={t(COMMON_TRANSLATE_KEYS.OPENING_HOURS)}
            value={openingHours}
            onChange={(e) => setOpeningHours(e.target.value)}
            onBlur={handleOpeningHoursBlur}
            width={18}
          />
        </div>
        <div className="w-1/2">
          <p className="text-mainWhite">
            {t(COMMON_TRANSLATE_KEYS.CLOSING_HOURS)}{" "}
            <span className="text-functionalRed">*</span>
          </p>
          <InputField
            placeholder={t(COMMON_TRANSLATE_KEYS.CLOSING_HOURS)}
            value={closingHours}
            onChange={(e) => setClosingHours(e.target.value)}
            onBlur={handleClosingHoursBlur}
            width={18}
          />
        </div>
        {hoursError && (
          <div className="text-functionalRed max-w-xl w-full break-words">
            {hoursError}
          </div>
        )}
      </div>
      <div className="mt-4 mb-4 w-full">
        <p className="text-mainWhite">
          {t(COMMON_TRANSLATE_KEYS.ADDITIONAL_INFO)}
          <span className="text-functionalRed">*</span>
        </p>
        <DescriptionInput
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          width={37}
          height={10}
        />
      </div>

      <div className="w-full flex sm:flex-col flex-row my-8 gap-4">
        <div className="flex sm:flex-row flex-col justify-around gap-2 sm:w-full w-1/2">
          <SwitchButton
            width={12}
            isSelected={parking}
            onClick={() => setParking(!parking)}
          >
            {t(COMMON_TRANSLATE_KEYS.PARKING)}
          </SwitchButton>
          <SwitchButton
            width={12}
            isSelected={dressing}
            onClick={() => setDressing(!dressing)}
          >
            {t(COMMON_TRANSLATE_KEYS.DRESSING_ROOM)}
          </SwitchButton>
          <SwitchButton
            width={12}
            isSelected={coffee}
            onClick={() => setCoffee(!coffee)}
          >
            {t(COMMON_TRANSLATE_KEYS.COFFEE_SHOP)}
          </SwitchButton>
        </div>
        <div className="flex sm:flex-row flex-col justify-around gap-2 sm:w-full w-1/2">
          <SwitchButton
            width={12}
            isSelected={showers}
            onClick={() => setShowers(!showers)}
          >
            {t(COMMON_TRANSLATE_KEYS.SHOWERS)}
          </SwitchButton>
          <SwitchButton
            width={12}
            isSelected={providesBalls}
            onClick={() => setProvidesBalls(!providesBalls)}
          >
            {t(COMMON_TRANSLATE_KEYS.PROVIDE_BALLS)}
          </SwitchButton>
          <SwitchButton
            width={12}
            isSelected={providesTShirts}
            onClick={() => setProvidesTShirts(!providesTShirts)}
          >
            {t(COMMON_TRANSLATE_KEYS.PROVIDE_T_SHIRTS)}
          </SwitchButton>
        </div>
      </div>

      <Divider mb={2} mt={2} width={36} />

      <div className="flex sm:flex-row flex-col-reverse justify-around mt-8 w-full gap-4">
        <Button
          textColor="text-mainWhite"
          backgroundColor="bg-functionalRed"
          onClick={() => handleCancel()}
        >
          {t(COMMON_TRANSLATE_KEYS.CANCEL)}
        </Button>
        <Button
          textColor="text-black-500"
          backgroundColor="bg-functionalGreen"
          onClick={handleSubmit}
        >
          {t(COMMON_TRANSLATE_KEYS.UPDATE)}
        </Button>
      </div>
    </div>
  );
};

export default EditArenaInfo;

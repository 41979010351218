import { Tab } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import EditProfileTab from "./tabs/EditProfile";
import MyFieldsTab from "./tabs/MyFieldsTab";
import ReservationTab from "./tabs/ReservationTab";
import { COMMON_TRANSLATE_KEYS } from "../../constants/translate-keys";

const Settings = () => {
  const { t } = useTranslation();

  return (
    <div className="text-white m-10">
      <Tab.Group>
        <Tab.List className="flex flex-row justify-between lg:max-w-[300px] md:gap-[29px] mb-8">
          <Tab
            className={({ selected }) =>
              `${selected ? "text-white border rounded-2xl border-white" : "text-gray-700 border rounded-2xl border-gray-700"} w-1/3 sm:border-none p-2 sm:p-0`
            }
          >
            {t(COMMON_TRANSLATE_KEYS.GENERAL)}
          </Tab>
          <Tab
            className={({ selected }) =>
              `${selected ? "text-white border rounded-2xl border-white" : "text-gray-700 border rounded-2xl border-gray-700"} sm:text-nowrap text-wrap w-1/3 sm:border-none p-2 sm:p-0`
            }
          >
            {t(COMMON_TRANSLATE_KEYS.MY_FIELDS)}
          </Tab>
          <Tab
            className={({ selected }) =>
              `${selected ? "text-white border rounded-2xl border-white" : "text-gray-700 border rounded-2xl border-gray-700"} w-1/3 sm:border-none p-2 sm:p-0`
            }
          >
            {t(COMMON_TRANSLATE_KEYS.RESERVATION)}
          </Tab>
        </Tab.List>

        <Tab.Panels>
          <Tab.Panel>
            <EditProfileTab />
          </Tab.Panel>

          <Tab.Panel>
            <MyFieldsTab />
          </Tab.Panel>

          <Tab.Panel>
            <ReservationTab />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default Settings;
